import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper, Tooltip, Typography } from "@material-ui/core";
import { getHaltmapData } from "../../redux/haltmap/haltmapActions";
import { selectStyleGenerator } from "../../lib/selectStyle";
import Map from "./haltmap";
import ReactGA from "react-ga";
import InfoIcon from "@material-ui/icons/Info";
import { ErrorBoundary } from "react-error-boundary";

const useStyles = makeStyles((theme) => ({
  chartHeader: {
    ...theme.typography.chartHeader,
    paddingTop: "2em",
    paddingBottom: "1em",
    paddingLeft: "2em",
    [theme.breakpoints.down("lg")]: {
      fontSize: "1rem",
    },
  },
  infoIcon: {
    paddingLeft: "0.5em",
    fontSize: "20px",
  },
  label: {
    fontFamily: "Montserrat",
    fontSize: "1rem",
    fontWeight: 500,
    color: "#919191",
  },
}));

// dummy data

// const value = [
//   {
//     halt_loc: [[28.57778, 77.53056]],
//     transporter: "SUBHAM CANTANINER SERVICE",
//     vehicle: "HR38X5117",
//   },
//   {
//     halt_loc: [
//       [28.83845, 77.29903],
//       [28.5364, 77.53513],
//     ],
//     transporter: "SUBHAM CANTANINER SERVICE",
//     vehicle: "HR38R7505",
//   },
//   {
//     halt_loc: [
//       [29.822607, 77.94144],
//       [28.521677, 77.53785],
//     ],
//     transporter: "SUBHAM CANTANINER SERVICE",
//     vehicle: "NL01N5362",
//   },
//   {
//     halt_loc: [[28.5289, 77.53136]],
//     transporter: "SUBHAM CONTANINER SERVICE",
//     vehicle: "HR63C5495",
//   },
//   {
//     halt_loc: [
//       [28.83845, 77.29903],
//       [28.52778, 77.52583],
//     ],
//     transporter: "SUBHAM CONTANINER SERVICE",
//     vehicle: "NL01AF8755",
//   },
// ];
function HaltMap(props) {
  const classes = useStyles();
  const customStyles = selectStyleGenerator({});
  const [state, setState] = useState({
    options: [],
    marker: [],
    source: [],
    destination: [],
  });

  useEffect(() => {
    props.getHaltmapData(props.filter);
  }, [props.filter]);

  useEffect(() => {
    if (Object.keys(props.haltmap).length > 0) {
      let haltmapData = Object.keys(props.haltmap).map((slice) => {
        return {
          label: props.haltmap[slice].key,
          value: JSON.stringify(props.haltmap[slice]),
        };
      });
      setState({
        options: haltmapData,
        marker: [],
        source: [],
        destination: [],
      });
    } else {
      setState({
        options: [],
        marker: [],
        source: [],
        destination: [],
      });
    }
  }, [props.haltmap]);

  const logEvent = (action) => {
    ReactGA.event({
      category: props.user,
      action,
    });
  };

  const laneChangeHandler = (event) => {
    let haltdata = JSON.parse(event.value);
    logEvent("Selected Lane in Halts Overview");
    setState({
      ...state,
      marker: haltdata ? haltdata.value : [],
      //marker : value
      source: haltdata ? haltdata.src : [],
      destination: haltdata ? haltdata.dest : [],
    });
  };

  const getByInfoTitle = (title) => {
    const infoTitle = {
      "Halts Overview": "Lane wise frequent halt locations",
    };
    return infoTitle[title] || title;
  };

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Paper elevation={4}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography className={classes.chartHeader}>
                Halts Overview
                <Tooltip
                  title={
                    <p style={{ fontSize: "14px", lineHeight: "1.2" }}>
                      {getByInfoTitle("Halts Overview")}
                    </p>
                  }
                >
                  <InfoIcon className={classes.infoIcon} />
                </Tooltip>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div style={{ width: "30em", paddingLeft: "3em" }}>
                <label className={classes.label}>Lane</label>
                <Select
                  id="lane"
                  key={state.options}
                  closeMenuOnSelect={true}
                  options={state.options}
                  styles={customStyles}
                  placeholder={"Select Lane"}
                  onChange={laneChangeHandler}
                  // defaultValue={selectedDropdownValue.srcname}
                />
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{
                paddingLeft: "3em",
                paddingRight: "3em",
                paddingBottom: "3em",
                paddingTop: "2em",
              }}
            >
              <ErrorBoundary fallback={<div>Something went wrong</div>}>
                <Map
                  data={state.marker}
                  src={state.source}
                  dest={state.destination}
                />
              </ErrorBoundary>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => {
  return {
    haltmap: { ...state.haltmap },
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getHaltmapData: (filter) => dispatch(getHaltmapData(filter)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HaltMap);
