import {
    GET_OVERVIEW_REQUEST,
    GET_OVERVIEW_SUCCESS,
    GET_OVERVIEW_FAILURE
} from './actionTypes';

import { overviewService } from '../../services/overviewService.js'

const fetchingOverview = ()=> ({
    type: GET_OVERVIEW_REQUEST
})

const overviewFetched = data => ({
    type: GET_OVERVIEW_SUCCESS,
    payload: data
})

const fetchFailure = err => ({
    type: GET_OVERVIEW_FAILURE,
    payload: err
})

export const getOverview = ( filter )=> {
    return ( dispatch )=> {

        dispatch( fetchingOverview() )
        
        overviewService.getOverview( filter )
            .then( res => dispatch( overviewFetched(res.data) ))
            .catch( err => dispatch( fetchFailure( err.response ) ))
    }
}