import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getDelayed } from "../../redux/delayed/delayedActions";
import { Grid, Paper, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import GroupedBar from "../highcharts/groupedBar";

const useStyles = makeStyles((theme) => ({
  chartHeader: {
    ...theme.typography.chartHeader,
    paddingTop: "2em",
    paddingBottom: "1em",
    paddingLeft: "2em",
    [theme.breakpoints.down("lg")]: {
      fontSize: "1rem",
    },
  },
  filterButtonActive: {
    ...theme.typography.filterButtonActive,
    paddingLeft: "1.5em",
    paddingRight: "1.5em",
    margin: "0.5em",
    [theme.breakpoints.down("lg")]: {
      fontSize: "0.68rem",
    },
  },
  filterButtonInactive: {
    ...theme.typography.filterButtonInactive,
    paddingLeft: "1.5em",
    paddingRight: "1.5em",
    margin: "0.5em",
    [theme.breakpoints.down("lg")]: {
      fontSize: "0.68rem",
    },
  },
}));

function BreachReport(props) {
  const classes = useStyles();
  const [filter, setfilter] = useState("");
  const [chartdata, setChartdata] = useState({
    categories: [],
    series: [],
  });

  useEffect(() => {
    props.getDelayed(props.filter);
  }, [props.filter]);

  useEffect(() => {
    setfilter(
      props.delayed.filterProperties[0]
        ? props.delayed.filterProperties[0].key
        : ""
    );
  }, [props.delayed.filterProperties]);

  useEffect(() => {
    let formattedchartdata = [
      {
        name: "Short Breach",
        data: [],
        stack: "male",
        color: "rgba(237, 189, 0, 0.5)",
      },
      {
        name: "Long Breach",
        data: [],
        stack: "female",
        color: "rgba(254, 88, 88, 0.5)",
      },
    ];
    let categories = [];
    if (filter) {
      formattedchartdata = props.delayed.data[filter].absolute.reduce(
        (prevres, item) => {
          prevres[0].data.push(item["Short Breach"]);
          prevres[1].data.push(item["Long Breach"]);
          categories.push(item.key);
          return prevres;
        },
        formattedchartdata
      );
    }
    setChartdata({
      ...chartdata,
      categories: categories,
      series: formattedchartdata,
    });
  }, [filter]);

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Paper elevation={4}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography className={classes.chartHeader}>
                {props.delayed.title}
              </Typography>
            </Grid>

            <Grid
              item
              xs={9}
              sm={9}
              md={9}
              lg={9}
              xl={9}
              style={{ paddingLeft: "2em", marginBottom: "2em" }}
            >
              {props.delayed.filterProperties.map((slice) => {
                return (
                  <Button
                    variant="outlined"
                    className={
                      slice.key === filter
                        ? classes.filterButtonActive
                        : classes.filterButtonInactive
                    }
                    onClick={() => {
                      setfilter(slice.key);
                    }}
                  >
                    {slice.label}
                  </Button>
                );
              })}
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div
                style={{ padding: "1em 2em 1em 2em" }}
                className="scroll-horizental-histo"
              >
                <GroupedBar
                  categories={chartdata.categories}
                  chartdata={chartdata.series}
                />
              </div>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => {
  return {
    ...state.delayed,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDelayed: (filter) => dispatch(getDelayed(filter)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BreachReport);
