import {  
    GET_TRIPSETA_REQUEST,
    GET_TRIPSETA_SUCCESS,
    GET_TRIPSETA_FAILURE
} from './actionTypes'
import { tripsETA } from '../../services/tripsETA.js'

const fetchingTrips = ()=> ({type: GET_TRIPSETA_REQUEST})

const tripsFetched = data => ({
    type: GET_TRIPSETA_SUCCESS,
    payload: data
})

const tripsFetchedFailure = err => ({
    type: GET_TRIPSETA_FAILURE
})

export const getTripsETA = (filter)=> {
    return ( dispatch )=> {
        dispatch( fetchingTrips() )

        tripsETA.getTripsETA(filter)
            .then( res => {
                dispatch( tripsFetched(res.data) )
            })
            .catch( err => {
                dispatch( tripsFetchedFailure(err.response) )
            })
    }
}