import {
  SIGNING_IN,
  SIGN_IN_SUCCESS,
  SIGN_IN_FAILURE,
  LOGOUT,
  TOKEN_VALIDATE_REQUEST,
  TOKEN_VALIDATE_SUCCESS,
  TOKEN_VALIDATE_FAILURE,
} from "./actionTypes";
import { authService } from "../../services/authService";

const signingIn = () => {
  return {
    type: SIGNING_IN,
  };
};

const signInSuccess = (data) => {
  return {
    type: SIGN_IN_SUCCESS,
    payload: data,
  };
};

const signInFailure = (err) => {
  return {
    type: SIGN_IN_FAILURE,
    payload: err,
  };
};

export const logout = () => {
  return {
    type: LOGOUT,
  };
};

export const signIn = (credentials) => {
  return (dispatch) => {
    dispatch(signingIn());

    authService
      .login(credentials)
      .then((res) => {
        if (res.status === 200) {
          dispatch(validateToken(res.data.result.token));
          // dispatch( signInSuccess(res.data) )
        }
      })
      .catch((err) => {
        console.log(err.response);
        dispatch(signInFailure(err));
      });
  };
};

const tokenValidating = () => ({ type: TOKEN_VALIDATE_REQUEST });

const tokenValidated = (data) => ({
  type: TOKEN_VALIDATE_SUCCESS,
  payload: data,
});

const tokenValidateFailure = (err) => ({
  type: TOKEN_VALIDATE_FAILURE,
  payload: err,
});

export const validateToken = (token) => {
  return (dispatch) => {
    dispatch(tokenValidating());

    authService
      .validateToken(token)
      .then((res) => {
        // console.log(res)
        dispatch(tokenValidated(res.data.result));
      })
      .catch((err) => {
        dispatch(tokenValidateFailure(err));
      });
  };
};
