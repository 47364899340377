import {
    GET_OVERVIEW_REQUEST,
    GET_OVERVIEW_SUCCESS,
    GET_OVERVIEW_FAILURE
} from './actionTypes'

let initState = {
    
}

export const overviewReducer = ( state = initState, action) => {

    switch(action.type){
        case GET_OVERVIEW_REQUEST:
            return {
                ...state
            }
        case GET_OVERVIEW_SUCCESS:
            return {
                ...state,
                ...action.payload
            }
        case GET_OVERVIEW_FAILURE:
            return {
                
            }
        default:
            return {
                ...state
            }
    }
}