import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import MaterialTable from "material-table";
import MuiDialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import XLSX from 'xlsx';
import moment from 'moment';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    background: "#43425D",
    color: "#FFFFFF",
    textAlign: "center",
  },
  modalTitle: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "24px",
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" className={classes.modalTitle}>
        {children}
      </Typography>
    </MuiDialogTitle>
  );
});

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  primary: {
    background: "#4949F0",
    borderRadius: "4px",
    color: "#ffffff",
    fontSize: "16px",
    fontWeight: 600,
    fontFamily: "Montserrat",
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    "&:hover": {
      background: "#4949F0",
      borderRadius: "4px",
      color: "#ffffff",
    },
  },
  downloadButton: {
    cursor: "pointer",    
    margin: "5px 15px 0 15px"
  }
}));

export default function MaxWidthDialog(props) {
  const classes = useStyles();

  const handleClose = () => {
    props.handleClose();
  };

  let title = "TRIP LIST";
  let column = [
    { title: "Transporter", field: "transporter" },
    { title: "Vehicle", field: "vehicle" },
    { title: "Source", field: "srcname" },
    { title: "Destination", field: "destname" },
  ];

  if (props.covidUser) {
    title = "CASE DETAILS";
    column = [
      { title: "Case Name", field: "case_name" },
      { title: "Quarantine Location", field: "destname" },
      {
        title: "Start Date",
        field: "quarantine_start_date",
        render: (rowData) =>
          new Date(rowData.quarantine_start_date).toLocaleDateString(),
      },
      { title: "Mobile Number", field: "tel[0]" },
    ];
  }

  const download = {
    freq: [
      // { title: "Shipment No.", field: "index" },
      { title: "Driver No.", field: "driver_number" },
      { title: "Operator", field: "operator" },
      { title: "Vehcile", field: "vehicle" },
      { title: "Transporter", field: "transporter" },
      { title: "Source", field: "srcname" },
      { title: "Destination", field: "destname" },
      // { title: "Dispatch Date", field: "destname" },
      { title: "Start Date", field: "trip_start_time" },
      { title: "Start Time", field: "trip_start_time" },
      { title: "Source Out", field: "src_out" },
      // { title: "Destination Reached Time", field: "trip_end_time" },
      { title: "End Date", field: "trip_end_time" },
      { title: "End Time", field: "trip_end_time" },
      // { title: "Travel Time (Hr)", field: "transit_time" },
      // { title: "Status (Hr)", field: "destname" },
      { title: "Tracked Status", field: "tracked" },
      { title: "Distance Travelled (km)", field: "distance_travelled" },
      // { title: "Distance Remaining (km)", field: "destname" },
    ],
    delay: [
      // { title: "Shipment No.", field: "index" },
      { title: "Driver No.", field: "driver_number" },
      { title: "Operator", field: "operator" },
      { title: "Vehcile", field: "vehicle" },
      { title: "Transporter", field: "transporter" },
      { title: "Source", field: "srcname" },
      { title: "Destination", field: "destname" },
      { title: "Start Time", field: "trip_start_time" },
    ],
    analyze: [
      // { title: "Shipment No.", field: "index" },
      { title: "Driver No.", field: "driver_number" },
      { title: "Operator", field: "operator" },
      { title: "Vehcile", field: "vehicle" },
      { title: "Transporter", field: "transporter" },
      { title: "Source", field: "srcname" },
      { title: "Destination", field: "destname" },
      // { title: "Dispatch Date", field: "destname" },
      { title: "Start Date", field: "trip_start_time" },
      { title: "Start Time", field: "trip_start_time" },
      { title: "Source Out", field: "src_out" },
      // { title: "Destination Reached Time", field: "trip_end_time" },
      { title: "End Date", field: "trip_end_time" },
      { title: "End Time", field: "trip_end_time" },
      // { title: "Travel Time (Hr)", field: "transit_time" },
      // { title: "Status (Hr)", field: "destname" },
      { title: "Tracked Status", field: "tracked" },
      { title: "Distance Travelled (km)", field: "distance_travelled" },
      // { title: "Distance Remaining (km)", field: "destname" },
    ]
  }

  const convert_secs_to_duration = (duration) => {
    if (duration > 86400) return (duration/86400).toFixed(0) + " days +"
    if (duration > 3600) return (duration/3600).toFixed(0) + " hours +"
    if (duration > 60) return (duration/60).toFixed(0) + " mins +"
    return duration + " secs"
  }

  const downloadExcel = (title) => {
    const data = props.data.map((row, index) => {
      let obj = {};
      download[(props.overview || 'freq').split("_")[0]].forEach((column, index) => {
        obj[column.title] = row[column.field]
      })
      // obj['Shipment No.'] = index + 1;
      obj['Driver No.'] = row.tel[0];
      if (props.overview.includes("delay")) {
        row.halts.forEach((halt, index) => {
          obj[`Halt ${index + 1} From`] = moment(new Date(halt.from_time)).format('DD-MM-YYYY @ hh:mm A')
          obj[`Halt ${index + 1} To`] = moment(new Date(halt.from_time + halt.duration)).format('DD-MM-YYYY @ hh:mm A')
          obj[`Halt ${index + 1} Interval`] = convert_secs_to_duration(halt.duration / 1000)
          obj[`Halt ${index + 1} Loc`] = halt.loc.join(",")
        })
      }
      return obj
    })
    const wb = XLSX.utils.book_new();
    const file_name = `${title}.xlsx`;
    const sheet_name = "Data"
    XLSX.utils.book_append_sheet(
        wb, XLSX.utils.json_to_sheet(data),
        sheet_name
    );
    XLSX.writeFile(wb, file_name);
  }

  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth={"xl"}
        open={props.open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title"> 
        {title}
        <CloudDownloadIcon className={classes.downloadButton} onClick={() => downloadExcel(title)}/>
        </DialogTitle>
        <DialogContent>
          <MaterialTable
            columns={column}
            data={props.data}
            title=""
            detailPanel={(rowData) => {
              return (
                <iframe
                  width="100%"
                  height="350"
                  src={rowData.publicLink}
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                />
              );
            }}
            onRowClick={(event, rowData, togglePanel) => togglePanel()}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className={classes.primary}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
