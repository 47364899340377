import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getSummary } from "../../redux/summary/summaryActions";
import {
  Grid,
  Paper,
  Typography,
  Button,
  Box,
  List,
  ListItem,
  ListItemText,
  LinearProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import TripListModal from "../TripsModal/tripListDialog";
import { tripService } from "../../services/tripService";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";

const useStyles = makeStyles((theme) => ({
  chartHeader: {
    ...theme.typography.chartHeader,
    [theme.breakpoints.down("lg")]: {
      fontSize: "1rem",
    },
  },
  headerBox: {
    textAlign: "center",
    marginTop: "0.7em",
    marginBottom: "0.7em",
    backgroundColor: "#F7F7F7",
    marginRight: "1em",
    marginLeft: "1em",
    padding: "0.3em",
  },
  sectionHeader: {
    ...theme.typography.sectionHeader,
    color: theme.palette.common.gunPowder,
    [theme.breakpoints.down("lg")]: {
      fontSize: "0.8rem",
    },
  },
  summaryHeader: {
    ...theme.typography.summaryHeader,
    paddingTop: "1em",
    [theme.breakpoints.down("lg")]: {
      fontSize: "1rem",
    },
  },
  filterButtonActive: {
    ...theme.typography.filterButtonActive,
    paddingLeft: "1.5em",
    paddingRight: "1.5em",
    margin: "1.5em 0.5em 1.5em 0.5em",
    [theme.breakpoints.down("lg")]: {
      fontSize: "0.68rem",
    },
  },
  filterButtonInactive: {
    ...theme.typography.filterButtonInactive,
    paddingLeft: "1.5em",
    paddingRight: "1.5em",
    margin: "0.5em",
    [theme.breakpoints.down("lg")]: {
      fontSize: "0.68rem",
    },
  },
  margin: {
    margin: theme.spacing(10),
  },
  body1: {
    ...theme.typography.body1,
    borderBottom: "1px solid #4949F0",
    display: "inline",
    paddingBottom: "4px",
    [theme.breakpoints.down("lg")]: {
      fontSize: "0.67rem",
      borderBottom: "0.70px solid #4949F0",
      display: "inline",
      paddingBottom: "3px",
    },
  },
  lpg: {
    height: 6,
    backgroundColor: "#FFDADA",
    borderRadius: 20,
  },
  bar: {
    borderRadius: 20,
    backgroundColor: "#ff6c5c",
  },
}));

function Summary(props) {
  const classes = useStyles();
  const [filter, setfilter] = useState({});
  const [open, setopen] = useState(false);
  const [trips, settrips] = useState([]);

  const clickHandler = (fieldKey, fieldName) => {
    if (!props.covidUser) {
      let { startDate, endDate } = props.filter;

      tripService
        .getTrips(fieldKey, fieldName, startDate, endDate)
        .then((res) => {
          settrips(res.data);
          setopen(true);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    props.getSummary(props.filter);
  }, [props.filter]);

  useEffect(() => {
    if (props.summary[0]) {
      let filter = {};
      props.summary.forEach((elems, index) => {
        filter[`filter-${index}`] = elems.topFrequentFilters[0].key;
      });
      setfilter(filter);
    }
  }, [props.summary]);

  const filterHandler = (cardIndex, value) => {
    let filterUpdated = {
      ...filter,
    };
    filterUpdated[cardIndex] = value;
    setfilter(filterUpdated);
  };

  let options = {
    cutoutPercentage: 80,
    legend: {
      display: true,
      position: "bottom",
    },
    layout: {
      padding: 40,
    },
  };

  let doughnutOptions = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: 0,
      plotShadow: false,
      height: (9 / 16) * 150 + "%",
    },
    title: {
      text: "Browser<br>shares<br>2017",
      align: "center",
      verticalAlign: "middle",
      style: {
        color: "#43425D",
        fontFamily: "Montserrat",
        fontWeight: 600,
        fontSize: "2em",
      },
    },
    tooltip: {
      pointFormat: "<b>{point.percentage:.1f}%</b>",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      pie: {
        colors: [],
        dataLabels: {
          enabled: false,
          distance: -50,
          style: {
            fontWeight: "bold",
            color: "white",
          },
        },
        startAngle: -180,
        endAngle: 180,
        /* center: ['50%', '75%'], */
        size: "90%",
        showInLegend: true,
      },
    },
    series: [
      {
        type: "pie",
        name: "",
        innerSize: "78%",
        data: [],
      },
    ],
  };

  return (
    <Grid container spacing={5}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Typography className={classes.chartHeader}>{props.title}</Typography>
      </Grid>
      {Object.keys(filter).length > 0 &&
        props.summary.map((eachCard, index) => {
          let doughnutOptionsScope = JSON.parse(
            JSON.stringify(doughnutOptions)
          );
          let sum = 0;
          let colors = [];
          let pieData = [];
          let firstData = 0;

          eachCard.distributions.forEach((element, index) => {
            if (index === 0) {
              firstData = element.value;
            }
            colors.push(element.color);
            pieData.push([element.label, element.value]);
            sum += element.value;
          });

          doughnutOptionsScope.plotOptions.pie.colors = colors;
          doughnutOptionsScope.series[0].data = pieData;
          doughnutOptionsScope.title.text = isNaN((firstData / sum) * 100)
            ? 0
            : ((firstData / sum) * 100).toFixed(2) + ` %`;

          return (
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              style={{ marginTop: "-1.5em", height: "100%" }}
            >
              <Paper elevation={4} style={{ minHeight: "20em" }}>
                <Grid container>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography className={classes.summaryHeader}>
                          {eachCard.title}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div>
                          <HighchartsReact
                            highcharts={Highcharts}
                            options={doughnutOptionsScope}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        style={{ textAlign: "center" }}
                      >
                        {eachCard.topFrequentFilters.map((slice) => {
                          let activeCard = `filter-${index}`;
                          return (
                            <Button
                              variant="outlined"
                              className={
                                slice.key === filter[`filter-${index}`]
                                  ? classes.filterButtonActive
                                  : classes.filterButtonInactive
                              }
                              onClick={() =>
                                filterHandler(`filter-${index}`, slice.key)
                              }
                            >
                              {slice.label}
                            </Button>
                          );
                        })}
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Box className={classes.headerBox}>
                          <Typography className={classes.sectionHeader}>
                            Most Frequent
                          </Typography>
                        </Box>
                        <List>
                          {eachCard.topFrequentsMap[
                            filter[`filter-${index}`]
                          ] &&
                            eachCard.topFrequentsMap[
                              filter[`filter-${index}`]
                            ].map((slice, index) => {
                              return (
                                <ListItem
                                  button={!props.covidUser}
                                  onClick={() =>
                                    clickHandler(
                                      slice.fieldKey,
                                      slice.fieldName
                                    )
                                  }
                                >
                                  <ListItemText
                                    primary={
                                      <Grid container>
                                        <Grid
                                          item
                                          xitem
                                          xs={4}
                                          sm={4}
                                          md={4}
                                          lg={4}
                                          xl={3}
                                        >
                                          <Typography className={classes.body1}>
                                            {slice.fieldType}
                                          </Typography>
                                        </Grid>
                                        <Grid
                                          item
                                          xs={8}
                                          sm={8}
                                          md={8}
                                          lg={8}
                                          xl={9}
                                          style={{ paddingLeft: "0.5em" }}
                                        >
                                          <Grid container>
                                            <Grid
                                              item
                                              xs={12}
                                              sm={12}
                                              md={12}
                                              lg={12}
                                              xl={12}
                                            >
                                              <Typography variant="body2">
                                                {slice.fieldName}
                                              </Typography>
                                            </Grid>
                                            <Grid
                                              item
                                              xs={10}
                                              sm={10}
                                              md={10}
                                              lg={10}
                                              xl={10}
                                              style={{
                                                display: "grid",
                                                alignItems: "center",
                                              }}
                                            >
                                              <LinearProgress
                                                variant="determinate"
                                                value={slice.valuePercent}
                                                classes={{
                                                  root: classes.lpg,
                                                  bar: classes.bar,
                                                }}
                                              />
                                            </Grid>
                                            <Grid
                                              item
                                              xs={2}
                                              sm={2}
                                              md={2}
                                              lg={2}
                                              xl={2}
                                              style={{ textAlign: "center" }}
                                            >
                                              <Typography variant="body2">
                                                {slice.value}
                                              </Typography>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    }
                                  />
                                </ListItem>
                              );
                            })}
                        </List>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          );
        })}
      <TripListModal
        handleClose={() => setopen(false)}
        open={open}
        data={trips}
        covidUser={props.covidUser}
      />
    </Grid>
  );
}

const mapStateToProps = (state) => {
  return {
    ...state.summary,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSummary: (filter) => dispatch(getSummary(filter)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Summary);
