import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { Filter } from "react-feather";
import { addDays } from "date-fns";
import { DatePicker } from "@material-ui/pickers";
import { Grid, Typography, Box } from "@material-ui/core";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { connect } from "react-redux";
import moment from 'moment';

const animatedComponents = makeAnimated();

const useStyles = makeStyles({
    list: {
        width: "100%",
    },
    fullList: {
        width: "auto",
    },
    filterButton: {
        textTransform: "none",
        margin: "2em",
        float: "right",
        fontFamily: "Montserrat",
        fontSize: "1rem",
    },
    filter: {
        padding: "1.5em 2em 1.5em 2em",
        fontSize: "1.25rem",
        fontWeight: 500,
    },
    paper: {
        width: "30%",
    },
    apply: {
        position: "absolute",
        bottom: 0,
        left: "50%",
        width: "100%",
        backgroundColor: "#4949F0",
        color: "#FFFFFF",
        fontSize: "1rem",
        fontWeight: 600,
        textAlign: "center",
    },
    filterAction: {
        display: "flex",
        position: "absolute",
        bottom: 0,
        width: "100%",
        cursor: "pointer",
    },
    filterInput: {
        width: "90%",
        paddingLeft: "1em",
        paddingTop: "2em",
        paddingBottom: ".5em",
    },
    filterLabel: {
        fontFamily: "Montserrat",
        fontSize: "1rem",
        fontWeight: 500,
        color: "#919191",
    },
    fromdate: {
        paddingRight: "2em",
    },
});

const customStyles = {
    control: (base) => ({
        ...base,
        minHeight: "3em",
        width: "100%",
        marginTop: "1em",
        border: "1px solid #4949F0",
        fontSize: "1rem",
        fontFamily: "Montserrat",
        backgroundColor: "transparent",
    }),
    menu: (base) => ({
        ...base,
        fontSize: "1rem",
        fontFamily: "Montserrat",
    }),
    menuList: (base) => ({
        ...base,
        fontSize: "1rem",
        fontFamily: "Montserrat",
    }),
    dropdownIndicator: (base) => ({
        ...base,
        padding: 4,
    }),
    clearIndicator: (base) => ({
        ...base,
        padding: 4,
    }),
    multiValue: (base) => ({
        ...base,
        // backgroundColor: variables.colorPrimaryLighter
    }),
    valueContainer: (base) => ({
        ...base,
        padding: "0px 6px",
    }),
    input: (base) => ({
        ...base,
        margin: 0,
        padding: 0,
    }),
};

function FilterFunc(props) {
    const classes = useStyles();
    const [drawerState, setDrawerState] = useState(false);
    const [selectedDropdownValue, setselectedDropdownValue] = useState({
        srcname: [],
        destname: [],
        transporter: [],
    });
    const [dropdowns, setDropdowns] = useState({});

    const baseState = {
        childuser: [],
        srcname: [],
        destname: [],
        transporter: [],
        startDate: props.app?.filtersDefaultValue?.fromTime ? new Date(props.app.filtersDefaultValue.fromTime).getTime() : new Date(moment(new Date(addDays(new Date().setHours(0, 0, 0, 0), -30))).startOf('day').toString()).getTime(),
        endDate: props.app?.filtersDefaultValue?.toTime ? new Date(props.app.filtersDefaultValue.toTime).getTime() : new Date(moment(new Date()).endOf('day').toString()).getTime(),
    };
    const [filterState, setFilterState] = useState(baseState);

    let { filters, childUser, filtersDefaultValue } = props.app;
    let { filterdata } = props.dashboard;

    React.useEffect(() => {
        const newState = {
            ...filterState, 
            startDate: props.app?.filtersDefaultValue?.fromTime ? new Date(props.app.filtersDefaultValue.fromTime).getTime() : baseState.startDate,
            endDate: props.app?.filtersDefaultValue?.toTime ? new Date(props.app.filtersDefaultValue.toTime).getTime() : baseState.endDate
        }
        setFilterState(state => (newState))
        // props.submitHandler(newState);
    }, [props.app?.filtersDefaultValue?.fromTime, props.app?.filtersDefaultValue?.toTime])

    useEffect(() => {
        let modifiedFillter = {};
        Object.keys(filterdata).forEach((each) => {
            modifiedFillter[each] = filterdata[each].reduce((prevres, item) => {
                prevres.push({ value: item, label: item });
                return prevres;
            }, []);
        });
        if (childUser) {
            modifiedFillter["childuser"] = childUser.map((slice) => ({
                value: slice,
                label: slice,
            }));
        }
        setDropdowns(modifiedFillter);
    }, [filterdata]);

    const filterChangeHandler = (event, target) => {
        let selectedValue = [];
        if (["srcname", "destname", "transporter"].includes(target)) {
            selectedValue =
                (Array.isArray(event) &&
                    event.reduce((prevres, item) => {
                        prevres.push(item.value);
                        return prevres;
                    }, selectedValue)) ||
                [];
            setFilterState({
                ...filterState,
                [target]: selectedValue,
            });
            setselectedDropdownValue({
                ...selectedDropdownValue,
                [target]: event,
            });
        } else if (target === "childuser") {
            setFilterState({
                ...filterState,
                [target]: event.value || "",
            });
            setselectedDropdownValue({
                ...selectedDropdownValue,
                [target]: event,
            });
        } else {
            let value = null;
            //this means startDate and endDate
            if (target === "endDate") value = moment(event).endOf('day').toString()
            else value = moment(event).startOf('day').toString();
            setFilterState({
                ...filterState,
                [target]: new Date(value).getTime(),
            });
        }
    };

    const clearFilter = () => {
        setselectedDropdownValue({
            srcname: [],
            destname: [],
            transporter: [],
            childuser: [],
        });
        setFilterState(baseState);
        // setDrawerState(false);
    };

    const toggleDrawer = (open) => (event) => {
        if (
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }

        setDrawerState(open);
    };

    const list = (anchor) => {
        return (
            <div
                className={clsx(classes.list, {
                    [classes.fullList]: anchor === "top" || anchor === "bottom",
                })}
                role="presentation"
            >
                <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography className={classes.filter}>
                            Filter
                        </Typography>
                        <Divider />
                    </Grid>
                    {childUser && childUser.length > 0 && (
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div className={classes.filterInput}>
                                <label
                                    htmlFor="subsidiaries"
                                    className={classes.filterLabel}
                                >
                                    Subsidiaries
                                </label>
                                <Select
                                    key={selectedDropdownValue.childuser}
                                    id="subsidiaries"
                                    placeholder="Search or Select"
                                    closeMenuOnSelect={true}
                                    options={dropdowns.childuser || []}
                                    styles={customStyles}
                                    onChange={(event) =>
                                        filterChangeHandler(event, "childuser")
                                    }
                                    defaultValue={
                                        selectedDropdownValue.childuser
                                    }
                                />
                            </div>
                        </Grid>
                    )}
                    {filters.srcname && (
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div className={classes.filterInput}>
                                <label
                                    htmlFor="source"
                                    className={classes.filterLabel}
                                >
                                    Source
                                </label>
                                <Select
                                    key={selectedDropdownValue.srcname}
                                    id="source"
                                    placeholder="Search or Select"
                                    closeMenuOnSelect={false}
                                    components={animatedComponents}
                                    isMulti
                                    options={dropdowns.srcname || []}
                                    styles={customStyles}
                                    onChange={(event) =>
                                        filterChangeHandler(event, "srcname")
                                    }
                                    defaultValue={selectedDropdownValue.srcname}
                                />
                            </div>
                        </Grid>
                    )}
                    {filters.destname && (
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div className={classes.filterInput}>
                                <label
                                    htmlFor="destination"
                                    className={classes.filterLabel}
                                >
                                    Destination
                                </label>
                                <Select
                                    key={selectedDropdownValue.destname}
                                    id="destination"
                                    placeholder="Search or Select"
                                    closeMenuOnSelect={false}
                                    components={animatedComponents}
                                    isMulti
                                    options={dropdowns.destname || []}
                                    styles={customStyles}
                                    onChange={(event) =>
                                        filterChangeHandler(event, "destname")
                                    }
                                    value={setselectedDropdownValue.destname}
                                    defaultValue={
                                        selectedDropdownValue.destname
                                    }
                                />
                            </div>
                        </Grid>
                    )}
                    {filters.transporter && (
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div className={classes.filterInput}>
                                <label
                                    htmlFor="transporter"
                                    className={classes.filterLabel}
                                >
                                    Transporter
                                </label>
                                <Select
                                    key={selectedDropdownValue.transporter}
                                    id="transporter"
                                    placeholder="Search or Select"
                                    closeMenuOnSelect={false}
                                    components={animatedComponents}
                                    isMulti
                                    options={dropdowns.transporter || []}
                                    styles={customStyles}
                                    onChange={(event) =>
                                        filterChangeHandler(
                                            event,
                                            "transporter"
                                        )
                                    }
                                    defaultValue={
                                        selectedDropdownValue.transporter
                                    }
                                />
                            </div>
                        </Grid>
                    )}
                    {filters.starttime && filters.endtime && (
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div
                                style={{
                                    display: "grid",
                                    width: "90%",
                                    paddingLeft: "1em",
                                    paddingTop: "2em",
                                    paddingBottom: ".5em",
                                }}
                            >
                                <label
                                    htmlFor="datefilter"
                                    className={classes.filterLabel}
                                >
                                    Date
                                </label>
                                <div
                                    style={{
                                        display: "flex",
                                        paddingTop: "1.5em",
                                    }}
                                >
                                    <DatePicker
                                        autoOk
                                        clearable
                                        maxDate={new Date()}
                                        format="dd-MM-yyyy"
                                        variant="inline"
                                        label="Basic example"
                                        value={filterState.startDate}
                                        onChange={(event) =>
                                            filterChangeHandler(
                                                event,
                                                "startDate"
                                            )
                                        }
                                        inputVariant="outlined"
                                        label="From"
                                        id="datefilter"
                                        className={classes.fromdate}
                                    />
                                    <DatePicker
                                        autoOk
                                        clearable
                                        maxDate={new Date()}
                                        format="dd-MM-yyyy"
                                        variant="inline"
                                        label="Basic example"
                                        value={filterState.endDate}
                                        onChange={(event) =>
                                            filterChangeHandler(
                                                event,
                                                "endDate"
                                            )
                                        }
                                        inputVariant="outlined"
                                        label="To"
                                    />
                                </div>
                            </div>
                        </Grid>
                    )}

                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        className={classes.filterAction}
                    >
                        <Box
                            bgcolor="#FFFFFF"
                            color="#4949F0"
                            p={4}
                            width="50%"
                            fontFamily="Montserrat"
                            textAlign="center"
                            fontSize="1rem"
                            fontWeight={600}
                            borderTop="1px solid #DBDBDB"
                            onClick={clearFilter}
                        >
                            CLEAR ALL
                        </Box>
                        <Box
                            bgcolor="#4949F0"
                            color="#FFFFFF"
                            p={4}
                            width="50%"
                            fontFamily="Montserrat"
                            textAlign="center"
                            fontSize="1rem"
                            fontWeight={600}
                            onClick={() => {
                                props.submitHandler(filterState);
                                setTimeout(() => setDrawerState(false), 500);
                            }}
                        >
                            APPLY
                        </Box>
                    </Grid>
                </Grid>
            </div>
        );
    };

    return (
        <div>
            <React.Fragment>
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.filterButton}
                    endIcon={<Filter />}
                    onClick={toggleDrawer(true)}
                >
                    Filter
                </Button>
                <Drawer
                    anchor={"right"}
                    open={drawerState}
                    onClose={toggleDrawer(false)}
                    classes={{
                        paper: classes.paper,
                    }}
                >
                    {list("right")}
                </Drawer>
            </React.Fragment>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        ...state,
    };
};

export default connect(mapStateToProps)(FilterFunc);
