import React, { useState, useEffect } from "react";
import {
  Grid,
  Paper,
  Typography,
  makeStyles,
  Box,
  List,
  ListItem,
  ListItemText,
  Tooltip,
} from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import { connect } from "react-redux";
import { getOverview } from "../../redux/overview/overviewActions";
import TripListModal from "../TripsModal/tripListDialog";
import { tripService } from "../../services/tripService";
import Trend from "react-trend";
import InfoIcon from '@material-ui/icons/Info';
import { getByTitle } from "@testing-library/react";

const useStyles = makeStyles((theme) => ({
  chartHeader: {
    ...theme.typography.chartHeader,
    [theme.breakpoints.down("lg")]: {
      fontSize: "1rem",
    },
    // paddingTop: '2em',
    // paddingLeft: '2em'
  },
  card: {
    height: "100%",
    minHeight: "23em",
    [theme.breakpoints.down("lg")]: {
      minHeight: "17em",
    },
  },
  paperHeader: {
    backgroundColor: theme.palette.common.gunPowder,
    display: "flex",
    alignItems: "center",
    borderRadius: "8px 8px 0px 0px",
  },
  infoIcon: {
    color: "white",
    paddingLeft: "0.5em",
    fontSize: "20px"
  },
  cardHeader: {
    ...theme.typography.cardHeader,
    color: "white",
    alignItems: "center",
    display: "flex",
    paddingLeft: "0.8em",
    [theme.breakpoints.down("lg")]: {
      fontSize: "1rem",
    },
  },
  overviewLogo: {
    marginLeft: "auto",
    paddingTop: "0.8em",
    paddingBottom: "0.5em",
    paddingRight: "30px",
    [theme.breakpoints.down("lg")]: {
      height: "2.3em",
    },
  },
  conterBoxContainer: {
    display: "flex",
    marginTop: "2em",
    marginBottom: "1em",
    alignItems: "center",
    justifyContent: "center",
  },
  counterBox: {
    backgroundColor: theme.palette.blackSqueeze.main,
    display: "block",
    borderRadius: "0.6em",
    textAlign: "center",
    paddingTop: "1em",
    paddingBottom: "1em",
    paddingRight: "1.5em",
    paddingLeft: "1.5em",
    minWidth: "3em",
    maxWidth: "6em",
  },
  counter: {
    ...theme.typography.counter,
    color: theme.palette.common.gunPowder,
    [theme.breakpoints.down("lg")]: {
      fontSize: "1.6rem",
    },
  },
  headerBox: {
    textAlign: "center",
    marginTop: "0.7em",
    marginBottom: "0.7em",
    backgroundColor: "#F7F7F7",
    marginRight: "1em",
    marginLeft: "1em",
    padding: "0.3em",
  },
  sectionHeader: {
    ...theme.typography.sectionHeader,
    color: theme.palette.common.gunPowder,
    [theme.breakpoints.down("lg")]: {
      fontSize: "0.8rem",
    },
  },
  body1: {
    ...theme.typography.body1,
    borderBottom: "1px solid #4949F0",
    display: "inline",
    paddingBottom: "4px",
    [theme.breakpoints.down("lg")]: {
      fontSize: "0.67rem",
      borderBottom: "0.70px solid #4949F0",
      display: "inline",
      paddingBottom: "3px",
    },
  },
  lpg: {
    height: 6,
    borderRadius: 20,
    [theme.breakpoints.down("lg")]: {
      height: 5,
    },
  },
  bar: {
    borderRadius: 20,
  },
  countSuffix: {
    fontSize: "1rem",
    fontFamily: "Montserrat",
    fontWeight: 500,
    color: "#43425D",
    [theme.breakpoints.down("lg")]: {
      fontSize: "0.7rem",
    },
  },
  positiveIndicator: {
    ...theme.typography.positiveIndicator,
    padding: "0.3em",
    [theme.breakpoints.down("lg")]: {
      fontSize: "1rem",
    },
  },
  negativeIndicator: {
    ...theme.typography.negativeIndicator,
    padding: "0.3em",
    [theme.breakpoints.down("lg")]: {
      fontSize: "1rem",
    },
  },
}));

function Overview(props) {
  const classes = useStyles();
  const [open, setopen] = useState(false);
  const [trips, settrips] = useState([]);
  const [selectedOverview, setSelectedOverview] = React.useState(null);

  const clickHandler = (fieldKey, fieldName) => {
    setSelectedOverview(fieldKey)
    if (!props.covidUser) {
      let { startDate, endDate } = props.filter;
      tripService
        .getTrips(fieldKey, fieldName, startDate, endDate)
        .then((res) => {
          settrips(res.data);
          setopen(true);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    props.getOverview(props.filter);
  }, [props.filter]);

  const getByInfoTitle = (title) => {
    const infoTitle = {
      'Total Completed Trips': 'Trips added on the dashboard and reached the destination location.',
      'Delayed Trips': 'Trips added on the dashboard and couldn\'t reach the destination within desired SLA.',
      'Total Analyzable Trips': 'Trips added on the dashboard and were marked source-out from source geofence and destination-in at destination geofence.'
    }
    return infoTitle[title] || title;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Typography className={classes.chartHeader}>{props.title}</Typography>
      </Grid>
      {props.overview &&
        props.overview.map((slice, index) => {
          let trendData =
            slice.trend && slice.trend.length > 0
              ? slice.trend.map((element) => {
                  return element[Object.keys(element)[0]];
                })
              : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
          return (
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <Paper elevation={4} className={classes.card}>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className={classes.paperHeader}
                  >
                    <Typography className={classes.cardHeader}>
                      {slice.title}
                      <Tooltip title={<p style={{fontSize: "14px", lineHeight: '1.2'}}>{getByInfoTitle(slice.title)}</p>}>
                          <InfoIcon className={classes.infoIcon}/>
                      </Tooltip>
                    </Typography>
                    <img
                      src={slice.imageSrc}
                      className={classes.overviewLogo}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sm={4}
                    md={4}
                    lg={4}
                    xl={4}
                    style={{ borderRight: "2px dashed #F0F4F9" }}
                  >
                    <div className={classes.conterBoxContainer}>
                      <Box className={classes.counterBox}>
                        <Typography
                          className={classes.counter}
                          variant="counter"
                        >
                          {slice.counts}
                        </Typography>
                        <Typography className={classes.countSuffix}>
                          {slice.countSuffix.toUpperCase()}
                        </Typography>
                      </Box>
                    </div>
                    {/* <Typography
                      className={
                        slice.countDiffrenceType === "+ve"
                          ? classes.positiveIndicator
                          : classes.negativeIndicator
                      }
                    >{`${slice.countDiffrence}%`}</Typography> */}
                    <div
                      style={{
                        paddingLeft: "1em",
                        paddingRight: "1em",
                        paddingTop: "3.5em",
                      }}
                    >
                      {slice.showTrend && (
                        <Trend
                          smooth
                          autoDraw
                          autoDrawDuration={4000}
                          autoDrawEasing="ease-out"
                          data={trendData}
                          gradient={["#f72047", "#ffd200", "#1feaea"]}
                          radius={15}
                          strokeWidth={6}
                          strokeLinecap={"round"}
                        />
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                    <Box className={classes.headerBox}>
                      <Typography className={classes.sectionHeader}>
                        Most Frequent
                      </Typography>
                    </Box>
                    <List>
                      {slice.topFrequents.map((eachFrequents, index) => {
                        return (
                          <ListItem
                            button={!props.covidUser}
                            // style={}
                            onClick={() =>
                              clickHandler(
                                eachFrequents.fieldKey,
                                eachFrequents.fieldName
                              )
                            }
                          >
                            <ListItemText
                              primary={
                                <Grid container>
                                  <Grid
                                    item
                                    xitem
                                    xs={4}
                                    sm={4}
                                    md={4}
                                    lg={4}
                                    xl={3}
                                  >
                                    <Typography className={classes.body1}>
                                      {eachFrequents.fieldType}
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={8}
                                    sm={8}
                                    md={8}
                                    lg={8}
                                    xl={9}
                                    style={{ paddingLeft: "1em" }}
                                  >
                                    <Grid container>
                                      <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                      >
                                        <Typography variant="body2">
                                          {eachFrequents.fieldName || "NA"}
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={10}
                                        sm={10}
                                        md={10}
                                        lg={10}
                                        xl={10}
                                        style={{
                                          display: "grid",
                                          alignItems: "center",
                                        }}
                                      >
                                        <LinearProgress
                                          variant="determinate"
                                          color="secondary"
                                          value={eachFrequents.valuePercent}
                                          classes={{
                                            root: classes.lpg,
                                            bar: classes.bar,
                                          }}
                                        />
                                      </Grid>
                                      <Grid
                                        item
                                        xs={2}
                                        sm={2}
                                        md={2}
                                        lg={2}
                                        xl={2}
                                        style={{ textAlign: "center" }}
                                      >
                                        <Typography variant="body2">
                                          {eachFrequents.value || 0}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              }
                            />
                          </ListItem>
                        );
                      })}
                    </List>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          );
        })}
      <TripListModal
        handleClose={() => setopen(false)}
        open={open}
        data={trips}
        overview={selectedOverview}
        covidUser={props.covidUser}
      />
    </Grid>
  );
}

const mapStateToProps = (state) => {
  return {
    ...state.overview,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getOverview: (filter) => dispatch(getOverview(filter)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Overview);
