import rootService from "./rootService";

export const tripService = {
  getTrips: (fieldKey, fieldValue, startDate, endDate) => {
    return rootService().sctAnalytics.get("/v3/trips", {
      params: {
        starttime: startDate,
        endtime: endDate,
        limit: "all",
        fieldKey: fieldKey,
        fieldValue: fieldValue,
      },
    });
  },
};
