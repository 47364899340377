import React, { useState, useEffect } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import Filter from "../Filter/filter";
import Overview from "../Overview";
import Summary from "../Summary";
import SCT_Summary from "../SCT_Summary";
import Delayed from "../Delayed";
import TripsETA from "../Trips";
import CQstatus from "../CQstatus";
import Heatmap from "../Heatmap";
import { addDays } from "date-fns";
import BreachReport from "../BreachReport";
import HaltMap from "../HaltMap";
import { fetchFilterdata } from "../../redux/dashboard/dashboardActions";
import { validateToken } from "../../redux/app/loginActions";
import queryString from "query-string";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { checkLoading } from "../../redux/loading/checkLoading";
import ReactGA from "react-ga";
import moment from "moment";
import { ErrorBoundary } from "react-error-boundary";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: "2em",
    paddingRight: "2em",
    paddingTop: "2em",
    backgroundColor: "#F0F4F9",
  },
  chartHeader: {
    ...theme.typography.chartHeader,
  },
  filterButton: {
    fontFamily: "Montserrat",
    fontSize: "1rem",
    color: "#2C4A70",
    padding: "0.5em 2.5em 0.5em 2.5em",
    textTransform: "none",
    margin: "0.5em",
    maxHeight: "2.9em",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const customStyles = {
  control: (base) => ({
    ...base,
    minHeight: "2.9em",
    width: "10em",
    marginTop: "0.5em",
    marginLeft: "0.5em",
    marginRight: "0.5em",
    border: "1px solid rgba(73, 73, 240, 0.5)",
    fontSize: "1rem",
    fontFamily: "Montserrat",
    backgroundColor: "transparent",
  }),
  dropdownIndicator: (base) => ({
    ...base,
    padding: 4,
  }),
  clearIndicator: (base) => ({
    ...base,
    padding: 4,
  }),
  multiValue: (base) => ({
    ...base,
    // backgroundColor: variables.colorPrimaryLighter
  }),
  valueContainer: (base) => ({
    ...base,
    padding: "0px 6px",
  }),
  input: (base) => ({
    ...base,
    margin: 0,
    padding: 0,
  }),
};

function Iframe(props) {
  const querystr = queryString.parse(props.location.search);
  const classes = useStyles();

  const [backDrop, setbackDrop] = useState(false);
  const [user, setUser] = useState(
    (props.location.search || "").split("user=")[1]
  );
  const [state, setState] = useState({
    startDate: props.app?.filtersDefaultValue?.fromTime
      ? new Date(props.app.filtersDefaultValue.fromTime).getTime()
      : new Date(
          moment(new Date(addDays(new Date().setHours(0, 0, 0, 0), -30)))
            .startOf("day")
            .toString()
        ).getTime(),
    endDate: props.app?.filtersDefaultValue?.toTime
      ? new Date(props.app.filtersDefaultValue.toTime).getTime()
      : new Date(moment(new Date()).endOf("day").toString()).getTime(),
  });

  const [filterValue, setfilterValue] = useState({});

  useEffect(() => {
    props.validateToken(querystr.token);
    setbackDrop(true);
  }, []);

  React.useEffect(() => {
    const newState = {
      ...state,
      startDate: props.app?.filtersDefaultValue?.fromTime
        ? new Date(props.app.filtersDefaultValue.fromTime).getTime()
        : new Date(
            moment(new Date(addDays(new Date().setHours(0, 0, 0, 0), -30)))
              .startOf("day")
              .toString()
          ).getTime(),
      endDate: props.app?.filtersDefaultValue?.toTime
        ? new Date(props.app.filtersDefaultValue.toTime).getTime()
        : new Date(moment(new Date()).endOf("day").toString()).getTime(),
    };
    setState((state) => newState);
  }, [
    props.app?.filtersDefaultValue?.fromTime,
    props.app?.filtersDefaultValue?.toTime,
  ]);

  useEffect(() => {
    if (props.app.loggedIn) {
      //setbackDrop(false);
      props.getFilterData(state);
      let allPossibleFilters = {};
      Object.keys(props.app.filters).map((slice) => {
        if (slice !== "starttime" && slice !== "endtime")
          allPossibleFilters[slice] = [];
      });
      setfilterValue(allPossibleFilters);
    }

    if (!props.app.validated) {
      props.history.push("/login");
    }
  }, [props.app.loggedIn]);

  useEffect(() => {
    if (!props.isLoading && props.app.loggedIn) {
      setbackDrop(false);
    } else {
      setbackDrop(true);
    }
  }, [props.isLoading]);

  const filters = props.app.filters;
  const filterdata = props.dashboard.filterdata;
  let filterOptions = {};

  Object.keys(filterdata).forEach((element) => {
    filterOptions[element] = [];
    filterdata[element].map((slice) => {
      filterOptions[element].push({
        value: slice,
        label: slice,
      });
    });
  });

  const filterSubmitHandler = (data) => {
    window.gtag("event", user, {
      event_category: "Applied Filters on Analytics page",
    });
    setState({
      ...state,
      ...data,
    });
  };

  return (
    <>
      <Backdrop className={classes.backdrop} open={backDrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {props.app.loggedIn ? (
        <Grid container spacing={2} className={classes.root}>
          {!props.app.covidUser && (
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{ marginBottom: "-5em" }}
            >
              <Filter submitHandler={filterSubmitHandler} />
            </Grid>
          )}
          {props.app.views && props.app.views.overview && (
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{ marginBottom: "1.5em" }}
            >
              <Overview filter={state} covidUser={props.app.covidUser} />
            </Grid>
          )}
          {props.app.views && props.app.views.sct_summary && (
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{ marginBottom: "1.5em" }}
            >
              <SCT_Summary
                filter={state}
                covidUser={props.app.covidUser}
                user={user}
              />
            </Grid>
          )}
          {props.app.views && props.app.views.covid_summary && (
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{ marginBottom: "2em" }}
            >
              <Summary filter={state} covidUser={props.app.covidUser} />
            </Grid>
          )}
          {props.app.views && props.app.views.sct_delay && (
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{ marginBottom: "2em" }}
            >
              <Delayed
                filter={state}
                covidUser={props.app.covidUser}
                user={user}
              />
            </Grid>
          )}
          {props.app.views && props.app.views.breach_report && (
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{ marginBottom: "2em" }}
            >
              <BreachReport filter={state} covidUser={props.app.covidUser} />
            </Grid>
          )}
          {props.app.views && props.app.views.halt_overview && (
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{ marginBottom: "2em" }}
            >
              <ErrorBoundary fallback={<div>Something went wrong</div>}>
                {" "}
                <HaltMap
                  filter={state}
                  covidUser={props.app.covidUser}
                  user={user}
                />
              </ErrorBoundary>
            </Grid>
          )}
          {props.app.views && props.app.views.sct_eta && (
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{ marginBottom: "2em" }}
            >
              <TripsETA
                filter={state}
                covidUser={props.app.covidUser}
                user={user}
              />
            </Grid>
          )}
          {props.app.views && props.app.views.quarantine_status && (
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{ marginBottom: "2em" }}
            >
              <CQstatus filter={state} covidUser={props.app.covidUser} />
            </Grid>
          )}
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            style={{ marginBottom: "2em" }}
          >
            <Heatmap
              filter={state}
              covidUser={props.app.covidUser}
              user={user}
            />
          </Grid>
        </Grid>
      ) : null}
    </>
  );
}

const checkLoadingStatus = checkLoading([
  "GET_OVERVIEW",
  "GET_SUMMARY",
  "GET_DELAYED",
  "GET_TRIPSETA",
  "GET_HEATMAP",
  "GET_FILTER_DATA",
  "GET_HALTMAP_DATA",
]);

const mapStateToProps = (state) => {
  return {
    ...state,
    isLoading: checkLoadingStatus(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getFilterData: (data) => dispatch(fetchFilterdata(data)),
    validateToken: (token) => dispatch(validateToken(token)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Iframe);
