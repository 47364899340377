import {
    GET_TRIPSETA_REQUEST,
    GET_TRIPSETA_SUCCESS,
    GET_TRIPSETA_FAILURE
} from './actionTypes'

let initState = {
    data: {},
    filterProperties: [],
    title: ""
}

export const tripsETA = (state = initState, action)=> {

    switch(action.type){
        case GET_TRIPSETA_REQUEST : 
            return {
                ...state
            }
        case GET_TRIPSETA_SUCCESS : 
            return {
                ...state,
                ...action.payload
            }
        case GET_TRIPSETA_FAILURE :
            return {
                ...state,
                data: {},
                filterProperties: [],
                title: ""
            }
        default :
            return {
                ...state
            }
    }

}