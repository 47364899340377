import React, { useState, useEffect } from "react";
import { Grid, Typography, makeStyles, Paper, Button, Tooltip } from "@material-ui/core";
import { connect } from "react-redux";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { getSummary } from "../../redux/summary/summaryActions";
import ReactGA from 'react-ga';
import InfoIcon from '@material-ui/icons/Info';

const useStyles = makeStyles((theme) => ({
  chartHeader: {
    ...theme.typography.chartHeader,
    [theme.breakpoints.down("lg")]: {
      fontSize: "1rem",
    },
    paddingBottom: "0.5em",
  },
  infoIcon: {
    paddingLeft: "0.5em",
    fontSize: "20px"
  },
  filterButtonActive: {
    ...theme.typography.filterButtonActive,
    paddingLeft: "2em",
    paddingRight: "2em",
    borderRadius: "8px",
    margin: "0.5em",
    [theme.breakpoints.down("lg")]: {
      fontSize: "0.68rem",
    },
  },
  filterButtonInactive: {
    ...theme.typography.filterButtonInactive,
    paddingLeft: "2em",
    paddingRight: "2em",
    borderRadius: "8px",
    margin: "0.5em",
    [theme.breakpoints.down("lg")]: {
      fontSize: "0.68rem",
    },
  },
  buttonContainer: {
    padding: "1.5em",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  sectionHeader: {
    ...theme.typography.sectionHeader,
    color: theme.palette.common.gunPowder,
    [theme.breakpoints.down("lg")]: {
      fontSize: "0.8rem",
    },
  },
  summaryHeader: {
    ...theme.typography.summaryHeader,
    // paddingTop: "1em",
    paddingBottom: "1.5em",
    [theme.breakpoints.down("lg")]: {
      fontSize: "1rem",
    },
  },
  mostFreqContainer: {
    backgroundColor: "#43425D",
    borderRadius: "6px 6px 0px 0px",
  },
  mostFreq: {
    color: "#FFFFFF",
    fontFamily: "Montserrat",
    fontSize: "1rem",
    fontWeight: 500,
    textAlign: "center",
    padding: "1em",
  },
}));

let doughnutOptions = {
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: 0,
    plotShadow: false,
    height: (9 / 16) * 150 + "%",
  },
  title: {
    text: "Browser<br>shares<br>2017",
    align: "center",
    verticalAlign: "middle",
    style: {
      color: "#43425D",
      fontFamily: "Montserrat",
      fontWeight: 600,
      fontSize: "2em",
    },
  },
  tooltip: {
    pointFormat: "<b>{point.percentage:.1f}%</b>",
  },
  accessibility: {
    point: {
      valueSuffix: "%",
    },
  },
  plotOptions: {
    pie: {
      colors: [],
      dataLabels: {
        enabled: false,
        distance: -50,
        style: {
          fontWeight: "bold",
          color: "white",
        },
      },
      startAngle: -180,
      endAngle: 180,
      /* center: ['50%', '75%'], */
      size: "90%",
      showInLegend: true,
    },
  },
  series: [
    {
      type: "pie",
      name: "",
      innerSize: "80%",
      data: [],
    },
  ],
};

let chartOptions = {
  chart: {
    type: "column",
    height: "65%",
    backgroundColor: "#F0F4F9",
    spacingBottom: 0,
  },
  title: {
    text: "",
  },
  xAxis: {
    visible: false,
    gridLineWidth: 0,
    categories: [],
    min: 0,
    max: 8,
    scrollbar: {
      enabled: true,
    },
    labels: {
      enabled: false,
    },
  },
  yAxis: {
    enabled: false,
    gridLineWidth: 0,
    min: 0,
    title: {
      text: "",
    },
    labels: {
      enabled: false,
    },
    stackLabels: {
      enabled: true,
      style: {
        fontFamily: "Montserrat",
        fontWeight: 500,
        fontSize: 18,
        color: "#43425D",
      },
    },
  },
  legend: {
    align: "right",
    x: -30,
    verticalAlign: "top",
    y: 25,
    floating: true,
    backgroundColor:
      Highcharts.defaultOptions.legend.backgroundColor || "white",
    borderColor: "#CCC",
    borderWidth: 1,
    shadow: false,
    enabled: false,
  },
  tooltip: {
    headerFormat: "<b>{point.x}</b><br/>",
    pointFormat: "{series.name}: {point.y}",
  },
  plotOptions: {
    column: {
      stacking: "normal",
      dataLabels: {
        enabled: false,
      },
      maxPointWidth: 10,
    },
  },
  series: [],
};

function SctSummary(props) {
  const { title, filterProperties, variations } = props;
  const classes = useStyles();
  const [filter, setfilter] = useState("source");

  const logEvent = (filter) => {
    ReactGA.event({
      category: props.user,
      action: `Clicked on ${filter} in Summary`
    });
  }

  useEffect(() => {
    props.getSummary(props.filter);
  }, [props.filter]);

  useEffect(() => {
    if (props.summary[0]) {
      let filter = {};
      props.summary.forEach((elems, index) => {
        filter[`filter-${index}`] = elems.topFrequentFilters[0].key;
      });
      setfilter(filter);
    }
  }, [props.summary]);

  const getByInfoTitle = (title) => {
    const infoTitle = {
      'Summary': 'Percentage of total trips that were added in the dashboard for which the pings were received and location was tracked.'
    }
    return infoTitle[title] || title;
  }

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography className={classes.chartHeader}>
              {title}
              {/* <Tooltip title={<p style={{fontSize: "14px", lineHeight: '1.2'}}>{getByInfoTitle(title)}</p>}>
                <InfoIcon className={classes.infoIcon}/>
              </Tooltip> */}
            </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Paper elevation={4}>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className={classes.buttonContainer}
            >
              {filterProperties &&
                filterProperties.map((slice) => {
                  return (
                    <Button
                      variant="outlined"
                      className={
                        slice.key === filter
                          ? classes.filterButtonActive
                          : classes.filterButtonInactive
                      }
                      onClick={() => {
                        logEvent(slice.label)
                        setfilter(slice.key);
                      }}
                    >
                      {slice.label}
                    </Button>
                  );
                })}
            </Grid>
            {variations &&
              variations.map((elems) => {
                let doughnutOptionsScope = JSON.parse(
                  JSON.stringify(doughnutOptions)
                );
                let sum = 0;
                let colors = [];
                let pieData = [];
                let firstData = 0;

                elems.distributions.forEach((item, index) => {
                  if (index === 0) {
                    firstData = item.value;
                  }
                  colors.push(item.color);
                  pieData.push([item.label, item.value]);
                  sum += item.value;
                });

                doughnutOptionsScope.plotOptions.pie.colors = colors;
                doughnutOptionsScope.series[0].data = pieData;
                doughnutOptionsScope.title.text = isNaN((firstData / sum) * 100)
                  ? 0
                  : ((firstData / sum) * 100).toFixed(2) + ` %`;

                let barChartData = {
                  labels: [],
                  datasets: [
                    {
                      name: "Trips",
                      color: "rgb(99, 50, 206, 0.4)",
                      data: [],
                    },
                  ],
                };

                let chartOptionsScope = JSON.parse(
                  JSON.stringify(chartOptions)
                );
                elems[filter].topFrequents.chartData &&
                  elems[filter].topFrequents.chartData.map((item) => {
                    barChartData.labels.push(item.key);
                    barChartData.datasets[0].data.push(item.value);
                  });

                barChartData.datasets[0].color =
                  elems[filter].topFrequents.color;
                chartOptionsScope.xAxis.categories = barChartData.labels;
                chartOptionsScope.series = barChartData.datasets;
                chartOptionsScope.xAxis.max =
                  barChartData.labels.length > 8
                    ? 8
                    : barChartData.labels.length - 1;

                return (
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Grid container style={{ padding: "1em 2em 1em 2em" }}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography className={classes.summaryHeader}>
                          {elems.title}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <div>
                          <HighchartsReact
                            highcharts={Highcharts}
                            options={doughnutOptionsScope}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Grid container>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            className={classes.mostFreqContainer}
                          >
                            <Typography className={classes.mostFreq}>
                              Most Frequents
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <HighchartsReact
                              highcharts={Highcharts}
                              options={chartOptionsScope}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => {
  return {
    ...state.summary,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSummary: (filter) => dispatch(getSummary(filter)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SctSummary);
