import {
    GET_DELAYED_REQUEST,
    GET_DELAYED_SUCCESS,
    GET_DELAYED_FAILURE
}from './actionTypes'

let initState = {
    delayed: {
        data: {},
        filterProperties:[],
        title: "",
        valueDividers: {}
    }
}

export const delayedReducer = (state = initState, action)=> {

    switch(action.type){
        case GET_DELAYED_REQUEST:
            return {
                ...state
            }
        case GET_DELAYED_SUCCESS:
            return {
                ...state,
                delayed: action.payload
            }
        case GET_DELAYED_FAILURE: 
            return {
                ...state,
                delayed: {
                    data: {},
                    filterProperties:[],
                    filterProperties:{},
                    title: "",
                    valueDividers: {}
                }
            }
        default :
            return {
                ...state
            }
    }
}