import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

import Login from "./components/Login/login";
import Dashboard from "./components/Dashboard";
import PrivateRoute from "./utils/privateRoute";
import AnalyticsIFrame from "./components/Dashboard/iframe";
import ReactGA from 'react-ga';

// import Apex from './components/Apex/line'

function App() {
  React.useEffect(() => {
    ReactGA.initialize('UA-154700795-2');
    console.log("initialized UA-154700795-2")
  }, [])
  return (
    <Router>
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/iframe" component={AnalyticsIFrame} />
        <PrivateRoute exact={false} path="/:key?" component={Dashboard} />
        {/* <Route exact path="/apex" component={Apex}/> */}
      </Switch>
    </Router>
  );
}

export default App;
