import {
  GET_HALTMAP_DATA_REQUEST,
  GET_HALTMAP_DATA_SUCCESS,
  GET_HALTMAP_DATA_FAILURE,
} from "./actionTypes";

const initState = {};

export const haltmapReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_HALTMAP_DATA_SUCCESS:
      return action.payload;
    case GET_HALTMAP_DATA_FAILURE:
      return {};
    default:
      return state;
  }
};
