import rootService from "./rootService";

export const summaryService = {
  getSummary: (filter) => {
    let { startDate, endDate, childuser } = filter;
    return rootService().sctAnalytics.post(
      `/v3/summary?starttime=${startDate}&endtime=${endDate}${
        childuser ? `&childUser=${childuser}` : ``
      }&limit=all`,
      {
        ...filter,
      }
    );
  },
};
