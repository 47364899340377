import rootService from "./rootService";

export const dashboardService = {
  getFilterdata: (data) => {
    // let start = 1584180787162,
    //   end = 1586859187162;
    let { startDate, endDate } = data;
    return rootService().sctAnalytics.get("/v3/filter", {
      params: {
        starttime: startDate,
        endtime: endDate,
      },
    });
  },
};
