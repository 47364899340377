import rootService from "./rootService";

export const overviewService = {
  getOverview: (filter) => {
    let { startDate, endDate, childuser } = filter;
    return rootService().sctAnalytics.post(
      `/v3/overview?starttime=${startDate}&endtime=${endDate}${
        childuser ? `&childUser=${childuser}` : ``
      }`,
      {
        ...filter,
      }
    );
  },
};
