import {
    GET_HEATMAP_REQUEST,
    GET_HEATMAP_SUCCESS,
    GET_HEATMAP_FAILURE
} from './actionTypes'
import { heatmapService } from '../../services/heatmapService'

const fetchingHeatmap = ()=> ({ type: GET_HEATMAP_REQUEST })

const heatmapFetched = data => ({
    type: GET_HEATMAP_SUCCESS,
    payload: data
})

const heatmapFetchFailure = err => ({
    type: GET_HEATMAP_FAILURE,
    payload: err
})

export const getHeatmap = (filter)=> {
    return ( dispatch )=> {

        dispatch( fetchingHeatmap() )

        heatmapService.getHeatmap(filter)
            .then( res => {
                dispatch( heatmapFetched(res.data) )
            })
            .catch( err => {
                dispatch( heatmapFetchFailure(err.response) )
            })

    }
}

