import {
  GET_FILTER_DATA_REQUEST,
  GET_FILTER_DATA_SUCCESS,
  GET_FILTER_DATA_FAILURE,
} from "./actionTypes";

import { dashboardService } from "../../services/dasboardService";

const fetchingFilterData = () => ({
  type: GET_FILTER_DATA_REQUEST,
});

const filterDataFetched = (data) => ({
  type: GET_FILTER_DATA_SUCCESS,
  payload: data,
});

const fetchFilterDataFailed = (err) => ({
  type: GET_FILTER_DATA_FAILURE,
  payload: err,
});

export const fetchFilterdata = (data) => {
  return (dispatch) => {
    dispatch(fetchingFilterData());

    dashboardService
      .getFilterdata(data)
      .then((res) => {
        dispatch(filterDataFetched(res.data.result));
      })
      .catch((err) => {
        dispatch(fetchFilterDataFailed(err.response));
      });
  };
};
