import {
    GET_SUMMARY_REQUEST,
    GET_SUMMARY_SUCCESS,
    GET_SUMMARY_FAILURE
} from './actionTypes'

let initState = {
    summary: [],
    title: ''
}

export const summaryReducer = ( state = initState, action )=> {

    switch(action.type){
        case GET_SUMMARY_REQUEST : 
            return {
                ...state
            }
        case GET_SUMMARY_SUCCESS :
            return {
                ...state,
                ...action.payload
            }
        case GET_SUMMARY_FAILURE :
            return {
                ...state,
                summary: []
            }
        default :
            return {
                ...state
            }
    }
}