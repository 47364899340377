import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getDelayed } from "../../redux/delayed/delayedActions";
import {
  Grid,
  Paper,
  Typography,
  Button,
  ButtonGroup,
  Tooltip,
} from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";
import Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import HighchartsReact from "highcharts-react-official";
import _ from "lodash";
import ReactGA from 'react-ga';
import InfoIcon from '@material-ui/icons/Info';
import GetAppIcon from '@material-ui/icons/GetApp';
import XLSX from 'xlsx';

HC_exporting(Highcharts)

const PrettoSlider = withStyles({
  root: {
    color: "#9BC5F8",
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#4949F0",
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
  mark: {
    height: 6,
  },
})(Slider);

const useStyles = makeStyles((theme) => ({
  chartHeader: {
    ...theme.typography.chartHeader,
    paddingTop: "2em",
    paddingLeft: "2em",
    [theme.breakpoints.down("lg")]: {
      fontSize: "1rem",
    },
  },
  infoIcon: {
    paddingLeft: "0.5em",
    fontSize: "20px"
  },
  downloadIcon: {
    paddingLeft: "0.5em",
    paddingTop: "0.1em",
    fontSize: "25px",
    cursor: "pointer"
  },
  typeButtonActive: {
    ...theme.typography.typeButtonActive,
    paddingLeft: "1.5em",
    paddingRight: "1.5em",
    [theme.breakpoints.down("lg")]: {
      fontSize: "0.68rem",
    },
  },
  typeButtonInactive: {
    ...theme.typography.typeButtonInactive,
    paddingLeft: "1.5em",
    paddingRight: "1.5em",
    [theme.breakpoints.down("lg")]: {
      fontSize: "0.68rem",
    },
  },
  filterButtonActive: {
    ...theme.typography.filterButtonActive,
    paddingLeft: "1.5em",
    paddingRight: "1.5em",
    margin: "0.5em",
    [theme.breakpoints.down("lg")]: {
      fontSize: "0.68rem",
    },
  },
  filterButtonInactive: {
    ...theme.typography.filterButtonInactive,
    paddingLeft: "1.5em",
    paddingRight: "1.5em",
    margin: "0.5em",
    [theme.breakpoints.down("lg")]: {
      fontSize: "0.68rem",
    },
  },
  delay: {
    fontSize: "1.25rem",
    fontFamily: "Montserrat",
    fontWeight: 500,
    [theme.breakpoints.down("lg")]: {
      fontSize: "0.84rem",
    },
  },
}));

function Delayed(props) {
  const classes = useStyles();
  const [filter, setfilter] = useState("");
  const [type, settype] = useState("absolute");
  const [sliderVal, setsliderVal] = useState(2);
  const chartRef = React.useRef(null);
  const { x } = props;

  let chartOptions = {
    exporting: {
      enabled: true,
      chartOptions: {
        xAxis: [{
          categories: [],
          max: 30,
          labels: {
            style: {
              fontSize: "7px"
            }
          }
        }]
      }
    },
    colors: ['rgba(40, 174, 20, 0.4)', 'rgba(237, 189, 16, 0.4)', 'rgba(254, 88, 88, 0.4)'],
    chart: {
      type: "column",
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: [],
      min: 0,
      max: 8,
      scrollbar: {
        enabled: true,
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "",
      },
      stackLabels: {
        enabled: false,
        style: {
          fontWeight: "bold",
          color:
            // theme
            (Highcharts.defaultOptions.title.style &&
              Highcharts.defaultOptions.title.style.color) ||
            "gray",
        },
      },
    },
    legend: {
      align: "right",
      x: -30,
      verticalAlign: "top",
      y: 25,
      floating: true,
      backgroundColor:
        Highcharts.defaultOptions.legend.backgroundColor || "white",
      borderColor: "#CCC",
      borderWidth: 1,
      shadow: false,
    },
    tooltip: {
      headerFormat: "<b>{point.x}</b><br/>",
      pointFormat: "{series.name}: {point.y}<br/>Total: {point.stackTotal}",
    },
    plotOptions: {
      column: {
        stacking: "normal",
        dataLabels: {
          enabled: false,
        },
        maxPointWidth: 100,
      },
    },
    series: [],
  };

  const logEvent = (action) => {
    ReactGA.event({
      category: props.user,
      action
    });
    console.log("tracking", {
      category: props.user,
      action
    })
  }

  const sliderHandler = (event, val) => {
    setsliderVal(val);
    logEvent("Used the Delay scrollbar in Delayed Report");
  };

  useEffect(() => {
    props.getDelayed(props.filter);
  }, [props.filter]);

  useEffect(() => {
    setfilter(
      props.delayed.filterProperties[0]
        ? props.delayed.filterProperties[0].key
        : ""
    );
  }, [props.delayed.filterProperties]);

  let chardata = {};
  let dataLength = 0;
  if (Object.keys(props.delayed.data).length > 0 && filter !== "") {
    let data = props.delayed.data[filter][type];
    chardata = {
      labels: [],
      datasets: props.delayed.valueDividers.dividers.map((slice) => {
        return {
          name:
            slice.dataKey === "ontime"
              ? slice.labelPrefix
              : `${slice.labelPrefix} ${sliderVal} ${slice.labelSuffix}`,
          // color: slice.color,
          data: [],
          dataKey:
            slice.dataKey === "ontime"
              ? slice.dataKey
              : `${sliderVal}${slice.dataKey}`,
        };
      }),
    };

    data.forEach((elems) => {
      chardata.labels.push(elems.name);
      chardata.datasets[0].data.push(
        _.get(elems, chardata.datasets[0].dataKey, 0)
      );
      chardata.datasets[1].data.push(
        _.get(elems, chardata.datasets[1].dataKey, 0)
      );
      chardata.datasets[2].data.push(
        _.get(elems, chardata.datasets[2].dataKey, 0)
      );
    });
    dataLength = chardata.labels.length;

    chartOptions.xAxis.categories = chardata.labels;
    chartOptions.exporting.chartOptions.xAxis[0].categories = chardata.labels;
    chartOptions.exporting.chartOptions.xAxis[0].max = (chardata.labels || []).length;
    chartOptions.series = chardata.datasets;
    chartOptions.xAxis.max =
      chardata.labels.length > 8 ? 8 : chardata.labels.length - 1;

    chartOptions.xAxis.scrollbar.enabled = chardata.labels.length > 8;
  }

  const getByInfoTitle = (title) => {
    const infoTitle = {
      'Delayed Report': 'Analysis of Delayed trips based on Source, Transporter, SLA'
    }
    return infoTitle[title] || title;
  }

  const downloadData = () => {
    try {
      console.log(props)
      const lanes = [];
      const sources = [];
      const transporters = [];
      const hour = sliderVal;
      if (props.delayed?.data?.lane?.absolute)
        props.delayed.data.lane.absolute.forEach(
          lane => {
            lanes.push({
              Name: lane.name,
              Ontime: lane.ontime,
              [`Delay Less than ${hour} hrs`]: lane[`${hour}hrs`]?.['>'],
              [`Delay More than ${hour} hrs`]: lane[`${hour}hrs`]?.['<']
            })
          }
        )
      if (props.delayed?.data?.source?.absolute)
        props.delayed.data.source.absolute.forEach(
          source => {
            sources.push({
              Name: source.name,
              Ontime: source.ontime,
              [`Delay Less than ${hour} hrs`]: source[`${hour}hrs`]?.['>'],
              [`Delay More than ${hour} hrs`]: source[`${hour}hrs`]?.['<']
            })
          }
        )
      if (props.delayed?.data?.transporter?.absolute)
        props.delayed.data.transporter.absolute.forEach(
          transporter => {
            transporters.push({
              Name: transporter.name,
              Ontime: transporter.ontime,
              [`Delay Less than ${hour} hrs`]: transporter[`${hour}hrs`]?.['>'],
              [`Delay More than ${hour} hrs`]: transporter[`${hour}hrs`]?.['<']
            })
          }
        )
      const wb = XLSX.utils.book_new();
      const file_name = `Delayed Report.xlsx`;
      XLSX.utils.book_append_sheet(
          wb, XLSX.utils.json_to_sheet(transporters),
          "Transporter"
      );
      XLSX.utils.book_append_sheet(
          wb, XLSX.utils.json_to_sheet(lanes),
          "Lane Data"
      );
      XLSX.utils.book_append_sheet(
          wb, XLSX.utils.json_to_sheet(sources),
          "Source Data"
      );
      XLSX.writeFile(wb, file_name);
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Paper elevation={4}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid container>
                <Typography className={classes.chartHeader}>
                  {props.delayed.title}
                  <Tooltip title={<p style={{fontSize: "14px", lineHeight: '1.2'}}>{getByInfoTitle(props.delayed.title)}</p>}>
                    <InfoIcon className={classes.infoIcon}/>
                  </Tooltip>
                  <Tooltip title={<p style={{fontSize: "14px", lineHeight: '1.2'}}>Download Data</p>}>
                    <GetAppIcon className={classes.downloadIcon} onClick={downloadData}/>
                  </Tooltip>
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{
                textAlign: "center",
                marginBottom: "3em",
              }}
            >
              <ButtonGroup
                color="primary"
                aria-label="outlined secondary button group"
              >
                <Button
                  className={
                    type === "absolute"
                      ? classes.typeButtonActive
                      : classes.typeButtonInactive
                  }
                  onClick={() => {
                    logEvent("Clicked on Absolute in Delayed Report");
                    settype("absolute")
                  }}
                >
                  Absolute
                </Button>
                <Button
                  className={
                    type === "percentage"
                      ? classes.typeButtonActive
                      : classes.typeButtonInactive
                  }
                  onClick={() => {
                    logEvent("Clicked on Percentage in Delayed Report");
                    settype("percentage")}
                  }
                >
                  Percentage
                </Button>
              </ButtonGroup>
            </Grid>
            <Grid
              item
              xs={9}
              sm={9}
              md={9}
              lg={9}
              xl={9}
              style={{ paddingLeft: "2em", marginBottom: "2em" }}
            >
              {props.delayed.filterProperties.map((slice) => {
                return (
                  <Button
                    variant="outlined"
                    className={
                      slice.key === filter
                        ? classes.filterButtonActive
                        : classes.filterButtonInactive
                    }
                    onClick={() => {
                      logEvent(`Clicked on ${slice.label} in Delayed Report`);
                      setfilter(slice.key);
                    }}
                  >
                    {slice.label}
                  </Button>
                );
              })}
            </Grid>
            <Grid
              item
              xs={3}
              sm={3}
              md={3}
              lg={3}
              xl={3}
              style={{ display: "flex" }}
            >
              <Typography className={classes.delay}>Delay:</Typography>
              &emsp;&emsp;
              {props.delayed.filterStepper && (
                <PrettoSlider
                  valueLabelDisplay="on"
                  onChange={sliderHandler}
                  aria-label="pretto slider"
                  defaultValue={props.delayed.filterStepper.min}
                  step={props.delayed.filterStepper.step}
                  min={props.delayed.filterStepper.min}
                  max={props.delayed.filterStepper.max}
                  marks
                />
              )}
              &emsp;&emsp;
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div
                style={{ padding: "1em 2em 1em 2em" }}
                className="scroll-horizental-histo"
              >
                {Object.keys(props.delayed.data).length > 0 && filter !== "" ? (
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={chartOptions}
                    ref={ chartRef }
                  />
                ) : null}
              </div>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => {
  return {
    ...state.delayed,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDelayed: (filter) => dispatch(getDelayed(filter)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Delayed);
