import rootService from "./rootService";

export const tripsETA = {
  getTripsETA: (filter) => {
    let { startDate, endDate, childuser } = filter;
    return rootService().sctAnalytics.post(
      `/v3/eta?starttime=${startDate}&endtime=${endDate}${
        childuser ? `&childUser=${childuser}` : ``
      }&limit=all`,
      {
        ...filter,
      }
    );
  },
};
