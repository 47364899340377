import {
  GET_HALTMAP_DATA_REQUEST,
  GET_HALTMAP_DATA_SUCCESS,
  GET_HALTMAP_DATA_FAILURE,
} from "./actionTypes";

import { haltmapService } from "../../services/haltService";

const fetching = () => ({
  type: GET_HALTMAP_DATA_REQUEST,
});

const fetchSuccess = (data) => ({
  type: GET_HALTMAP_DATA_SUCCESS,
  payload: data,
});

const fetchFailed = (err) => ({
  type: GET_HALTMAP_DATA_FAILURE,
  payload: err,
});

export const getHaltmapData = (filter) => {
  return (dispatch) => {
    dispatch(fetching());

    haltmapService
      .getHaltmap(filter)
      .then((res) => dispatch(fetchSuccess(res.data)))
      .catch((err) => dispatch(fetchFailed(err.response)));
  };
};
