import React, { Component } from "react";
import mapboxgl from "mapbox-gl";
import ReactGA from "react-ga";
import { ErrorBoundary } from "react-error-boundary";

// mapboxgl.accessToken = 'pk.eyJ1IjoiaW50dWdpbmV0ZWNobm9sb2dpZXMiLCJhIjoiY2s1NTk1N2tmMDdzMTNsbzNqMXN1MHdwaiJ9.v_0VaJjBB4Z_deeQ_WulHw';
// mapboxgl.accessToken = `pk.eyJ1IjoibG92ZXByZWV0c2luZ2giLCJhIjoiY2xqZm53aDhkMDJ0NTNsdXBmbGZwd3NjNiJ9.tyfH6qc7p4v-UFzNc8Rkww`;
   mapboxgl.accessToken = `pk.eyJ1IjoiYXl1c2hpbnR1Z2luZSIsImEiOiJjbHMxaWdramYwZzV3Mm1tazU4aTA5MzlkIn0.2EpIwfNReCEYLuSmPRT3Ag`;

export class Map extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lng: 78.9629,
      lat: 20.5937,
      zoom: 4,
      legend: [],
      isLoaded: false,
    };

    this.legend = [];
  }

  componentDidMount() {
    this.map = new mapboxgl.Map({
      container: this.mapContainer,
      style: "mapbox://styles/mapbox/dark-v10",
      center: [78.9629, 20.5937],
      zoom: 4,
    });

    this.map.addControl(new mapboxgl.FullscreenControl());
    this.map.addControl(new mapboxgl.NavigationControl());
    this.map.on("load", () => {
      this.setState((prevState) => ({ ...prevState, isLoaded: true }));
    });
  }

  componentDidUpdate() {
    let heatmapData = {
      type: "FeatureCollection",
      features: [],
    };

    let heatmapData1 = {};
    let legendStore = [];

    Object.keys(this.props.data).forEach((slice, index) => {
      heatmapData1[slice] = {
        type: "FeatureCollection",
        features: [],
      };
      this.props.data[slice].forEach((element, index) => {
        let ecahPoint = {
          type: "Feature",
          properties: {
            id: `${slice}-heatmap-${index}`,
          },
          geometry: {
            type: "Point",
            coordinates: element.reverse(),
          },
        };
        heatmapData.features.push(ecahPoint);
        heatmapData1[slice].features.push(ecahPoint);
      });
    });

    Object.keys(heatmapData1).map((key, index) => {
      let circleColour = [];
      let color = "#11b4da";
      if (this.props.covidUser) {
        circleColour = [
          "step",
          ["get", "point_count"],
          "#51bbd6",
          100,
          "#f1f075",
          201,
          "#f28cb1",
        ];
      } else {
        if (key === "source") {
          color = "#f1f075";
          circleColour = [
            "step",
            ["get", "point_count"],
            "#f1f075",
            100,
            "#f1f075",
            750,
            "#f1f075",
          ];
          legendStore.push({ name: "Source", color: "#f1f075" });
        } else {
          color = "#28AE14";
          circleColour = [
            "step",
            ["get", "point_count"],
            "#28AE14",
            100,
            "#28AE14",
            750,
            "#28AE14",
          ];
          legendStore.push({ name: "Destination", color: "#28AE14" });
        }
      }

      if (this.map && this.state.isLoaded) {
        if (this.map.getLayer(`cutomMarker-${key}-${index}`))
          this.map.removeLayer(`cutomMarker-${key}-${index}`);

        if (this.map.getLayer(`cluster-count-${key}-${index}`))
          this.map.removeLayer(`cluster-count-${key}-${index}`);

        if (this.map.getLayer(`unclustered-${key}-${index}`))
          this.map.removeLayer(`unclustered-${key}-${index}`);

        if (this.map.getSource(`cutomMarker-${key}-${index}`))
          this.map.removeSource(`cutomMarker-${key}-${index}`);

        this.map.addSource(`cutomMarker-${key}-${index}`, {
          type: "geojson",
          data: heatmapData1[key],
          cluster: true,
          clusterMaxZoom: 14, // Max zoom to cluster points on
          clusterRadius: 50,
        });

        this.map.addLayer({
          id: `cutomMarker-${key}-${index}`,
          type: "circle",
          source: `cutomMarker-${key}-${index}`,
          filter: ["has", "point_count"],
          paint: {
            // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
            // with three steps to implement three types of circles:
            //   * Blue, 20px circles when point count is less than 100
            //   * Yellow, 30px circles when point count is between 100 and 750
            //   * Pink, 40px circles when point count is greater than or equal to 750
            "circle-color": circleColour,
            "circle-radius": [
              "step",
              ["get", "point_count"],
              20,
              100,
              30,
              750,
              40,
            ],
          },
        });

        this.map.addLayer({
          id: `cluster-count-${key}-${index}`,
          type: "symbol",
          source: `cutomMarker-${key}-${index}`,
          filter: ["has", "point_count"],
          layout: {
            "text-field": "{point_count_abbreviated}",
            "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
            "text-size": 12,
          },
        });

        this.map.addLayer({
          id: `unclustered-${key}-${index}`,
          type: "circle",
          source: `cutomMarker-${key}-${index}`,
          filter: ["!", ["has", "point_count"]],
          paint: {
            "circle-color": color,
            "circle-radius": 7,
            "circle-stroke-width": 1,
            "circle-stroke-color": "#fff",
          },
        });
      }
    });

    this.legend = legendStore;

    this.map.on("load", () => {
      // var layers = ['0-10', '10-20', '20-50', '50-100', '100-200', '200-500', '500-1000', '1000+'];
      // var colors = ['#FFEDA0', '#FED976', '#FEB24C', '#FD8D3C', '#FC4E2A', '#E31A1C', '#BD0026', '#800026'];
      // for (let i = 0; i < layers.length; i++) {
      //     var layer = layers[i];
      //     var color = colors[i];
      //     var item = document.createElement('div');
      //     var key = document.createElement('span');
      //     key.className = 'legend-key';
      //     key.style.backgroundColor = color;
      //     var value = document.createElement('span');
      //     value.innerHTML = layer;
      //     item.appendChild(key);
      //     item.appendChild(value);
      //     var legend = document.getElementById('legend')
      //     legend.appendChild(item);
      //   }
    });
  }

  logEvent(action) {
    ReactGA.event({
      category: this.props.user,
      action,
    });
  }

  render() {
    return (
      <ErrorBoundary fallback={<div>Something went wrong</div>}>
        <>
          <div
            id="map"
            ref={(el) => (this.mapContainer = el)}
            onClick={() => this.logEvent("Clicked on Map to view trip heatmap")}
          ></div>
          {!this.props.covidUser ? (
            <div id="state-legend" class="legend">
              <h4>Legend</h4>
              <div key="source">
                <span style={{ backgroundColor: "#f1f075" }}></span>Source
              </div>
              <div key="distination">
                <span style={{ backgroundColor: "#28AE14" }}></span>Destination
              </div>
            </div>
          ) : (
            <div id="state-legend" class="legend">
              <h4>Legend</h4>
              <div key="99">
                <span style={{ backgroundColor: "#51bbd6" }}></span> 0 - 99
              </div>
              <div key="100">
                <span style={{ backgroundColor: "#f1f075" }}></span>100 - 200
              </div>
              <div key="200">
                <span style={{ backgroundColor: "#f28cb1" }}></span> 200
              </div>
            </div>
          )}
        </>
      </ErrorBoundary>
    );
  }
}

export default Map;
