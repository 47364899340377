import React from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Logo from '../../assets/Intugine.png'
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import {connect} from 'react-redux'
import { signIn } from '../../redux/app/loginActions'
import { useEffect } from 'react'
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    root: {
      height: '100vh',
    },
    paper: {
        margin: theme.spacing(16, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    company:{
        fontFamily: 'Montserrat',
        fontSize: 34,
        fontWeight: 'bold',
        color: '#43425D'
    },
    goal:{
        fontFamily: 'Montserrat',
        fontSize: 21,
        fontWeight: 'medium',
        color: '#919191'
    },
    username: {
        borderRadius: 30,
        marginTop: '20%',
        width: '60%'
    },
    password: {
        marginTop: '5%',
        width: '60%'
    },
    login_button:{
        marginTop: '5%',
        backgroundColor: '#EDBD00',
        width: '60%',
        textTransform: 'none',
        fontFamily: 'Montserrat',
        fontSize: 20,
        fontWeight: 'bold',
        color: '#FFFFFF'
    },
    input_label:{
        color: '#4949F0',
        fontSize: '12px',
        fontWeight: 'bolder'
    },
    login_input_label:{
        color: '#4949F0',
        fontSize: '12px',
        fontWeight: 'bolder'
    },
    login_input:{
        borderRadius: '30px'
    },
    login_input_field:{
        width: '60%',
        marginTop: '5%'
    },
    platform:{
        fontSize: '16px',
        color: '#43425D',
        backgroundColor: '#F0F4F9',
        marginTop: '55px',
        padding: '5px',
        fontFamily: 'Montserrat',
        fontWeight: 500
    }
  }));

function Login(props) {
    const classes = useStyles();
    const [values, setValues] = React.useState({
        username: '',
        amount: '',
        password: '',
        weight: '',
        weightRange: '',
        showPassword: false,
      });

      useEffect(() => {
        if(props.loggedIn)
            props.history.push('/')
      }, [props.loggedIn])
    
      const handleChange = prop => event => {
        setValues({ ...values, [prop]: event.target.value });
      };
    
      const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
      };
    
      const handleMouseDownPassword = event => {
        event.preventDefault();
      };

      const signinHandler = event => {
          event.preventDefault()
          
        props.login({username: values.username, password: values.password})
      }

    return (
        <div>
            <Backdrop className={classes.backdrop} open={props.loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Grid container component="main" className={classes.root} >
                <Grid item sm={false} md={8} lg={8} xl={8} >
                    <img src="https://assetsstatic.s3.ap-south-1.amazonaws.com/illustration1.png" width="100%" style={{height: '100vh'}}></img>
                </Grid>

                <Grid item md={4} lg={4} xl={4} component={Paper} elevation={6} square>
                    <div className={classes.paper}>
                        <img src={Logo}/>
                        <Typography component="h1" variant="h6" className={classes.company}>
                            Intugine Technologies
                        </Typography>
                        <Typography component="h1" variant="body1" className={classes.goal}>
                            Logistics through innovation
                        </Typography>

                        <Typography component="h1" variant="body1" className={classes.platform}>
                            Intutrack Platform
                        </Typography>
                        <form onSubmit={signinHandler} style={{display: 'contents'}}>
                        <FormControl className={clsx(classes.margin, classes.textField, classes.login_input_field)} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password" className={classes.login_input_label}>Username</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-password"
                            className={classes.login_input}
                            type="type"
                            value={values.username}
                            onChange={handleChange('username')}
                            labelWidth={60}
                        />
                        </FormControl>
                        


                        <FormControl className={clsx(classes.margin, classes.textField, classes.login_input_field)} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password" className={classes.login_input_label}>Password</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-password"
                            className={classes.login_input}
                            type={values.showPassword ? 'text' : 'password'}
                            value={values.password}
                            onChange={handleChange('password')}
                            endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                                >
                                {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                            }
                            labelWidth={60}
                        />
                        </FormControl>
                        <Button variant="contained" type="submit" className={classes.login_button} onClick={signinHandler}>Log In</Button>
                        </form>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        ...state.app
    }
}

const mapDispatchToProps = (dispatch)=>{
    return{
        login: (credentials)=> dispatch( signIn(credentials) )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
