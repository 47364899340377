import {
    GET_DELAYED_REQUEST,
    GET_DELAYED_SUCCESS,
    GET_DELAYED_FAILURE 
} from './actionTypes'
import { delayedService } from '../../services/delayedService'


const fetchingDelayed = ()=> ({type: GET_DELAYED_REQUEST})

const delayedFetched = data => ({
    type: GET_DELAYED_SUCCESS,
    payload: data
})

const delayedFetchFailure = err => ({
    type: GET_DELAYED_FAILURE,
    payload: err
})

export const getDelayed = (filter)=> {
    return (dispatch)=>{

        dispatch( fetchingDelayed() )

        delayedService.getDelayed(filter)
            .then( res => {
                dispatch( delayedFetched(res.data))
            })
            .catch(( err )=> {
                console.log(err)
            })
    }
}