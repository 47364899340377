import React, { Component } from "react";
import mapboxgl from "mapbox-gl";
// mapboxgl.accessToken ="pk.eyJ1IjoiaW50dWdpbmV0ZWNobm9sb2dpZXMiLCJhIjoiY2s1NTk1N2tmMDdzMTNsbzNqMXN1MHdwaiJ9.v_0VaJjBB4Z_deeQ_WulHw";
// mapboxgl.accessToken = `pk.eyJ1IjoibG92ZXByZWV0c2luZ2giLCJhIjoiY2xqZm53aDhkMDJ0NTNsdXBmbGZwd3NjNiJ9.tyfH6qc7p4v-UFzNc8Rkww`;
mapboxgl.accessToken = `pk.eyJ1IjoiYXl1c2hpbnR1Z2luZSIsImEiOiJjbHMxaWdramYwZzV3Mm1tazU4aTA5MzlkIn0.2EpIwfNReCEYLuSmPRT3Ag`;

export class Haltmap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lng: 78.9629,
      lat: 20.5937,
      zoom: 4,
      legend: [],
    };

    this.legend = [];
    this.markers = [];
  }

  clearMarkers() {
    this.markers.forEach((marker) => marker.remove());
    this.markers = [];
  }

  componentDidMount() {
    this.map = new mapboxgl.Map({
      container: this.mapContainer,
      style: "mapbox://styles/mapbox/dark-v10",
      center: [78.9629, 20.5937],
      zoom: 4,
    });

    this.map.addControl(new mapboxgl.FullscreenControl());
    this.map.addControl(new mapboxgl.NavigationControl());

    //old function

    // this.map.on("click", (event) => {
    //   console.log("event", event);
    //   let locArray = [event.lngLat.lng, event.lngLat.lat];
    //   new mapboxgl.Popup({ offset: [0, -15] })
    //     .setLngLat(locArray)
    //     .setHTML(
    //       "<h3>" +
    //         "Location" +
    //         "</h3><p>" +
    //         `Longitude: ${event.lngLat.lng}</br>Latitude: ${event.lngLat.lat}` +
    //         "</p>"
    //     )
    //     .addTo(this.map);
    // });
  }

  componentDidUpdate() {
    //dummy data
    // const d = [
    //   {
    //     halt_loc: [[28.57778, 77.53056]],
    //     transporter: "SUBHAM CANTANINER SERVICE",
    //     vehicle: "HR38X5117",
    //   },
    //   {
    //     halt_loc: [
    //       [28.83845, 77.29903],
    //       [28.5364, 77.53513],
    //     ],
    //     transporter: "SUBHAM CANTANINER SERVICE",
    //     vehicle: "HR38R7505",
    //   },
    //   {
    //     halt_loc: [
    //       [29.822607, 77.94144],
    //       [28.521677, 77.53785],
    //     ],
    //     transporter: "SUBHAM CANTANINER SERVICE",
    //     vehicle: "NL01N5362",
    //   },
    //   {
    //     halt_loc: [[28.5289, 77.53136]],
    //     transporter: "SUBHAM CONTANINER SERVICE",
    //     vehicle: "HR63C5495",
    //   },
    //   {
    //     halt_loc: [
    //       [28.83845, 77.29903],
    //       [28.52778, 77.52583],
    //     ],
    //     transporter: "SUBHAM CONTANINER SERVICE",
    //     vehicle: "NL01AF8755",
    //   },
    // ];

    // const coordinateArray = [];

    //this.props.data
    // d.map((x) => x?.halt_loc?.map((y) => coordinateArray.push(y)));

    //halt marker
    this.clearMarkers();
    this.props.data.map((x) =>
      x?.halt_loc?.map((y) => {
        if (Array.isArray(y)) {
          const popup = new mapboxgl.Popup({ offset: [0, -15] }).setHTML(
            "<h3>" +
              "Location" +
              "</h3><p>" +
              `Longitude: ${y[0]}</br>Latitude: ${y[1]}</br>Transporter: ${x?.transporter}</br>Vehicle No.: ${x?.vehicle}` +
              "</p>"
          );
          // coordinateArray.forEach((coordinates) => {
          var el = document.createElement("div");
          el.className = "halt_marker";
          let eachmarker = new mapboxgl.Marker(el)
            .setLngLat(y.reverse())
            .setPopup(popup)
            .addTo(this.map);

          this.markers.push(eachmarker);
          // });
        }
      })
    );

    //src marker
    if (this.props.src.length) {
      var el = document.createElement("div");
      el.className = "source_marker";
      let eachmarker = new mapboxgl.Marker(el)
        .setLngLat(this.props.src.reverse())
        .addTo(this.map);
      this.markers.push(eachmarker);
    }

    //dest marker
    if (this.props.dest.length) {
      var el = document.createElement("div");
      el.className = "destination_marker";
      let eachmarker = new mapboxgl.Marker(el)
        .setLngLat(this.props.dest.reverse())
        .addTo(this.map);
      this.markers.push(eachmarker);
    }
  }

  render() {
    return <div id="map" ref={(el) => (this.mapContainer = el)}></div>;
  }
}

export default Haltmap;
