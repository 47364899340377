import React from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";

function GroupedBar(props) {
  let chartOptions = {
    chart: {
      type: "column",
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: [],
      min: 0,
      max: 8,
      scrollbar: {
        enabled: true,
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "",
      },
      stackLabels: {
        enabled: false,
        style: {
          fontWeight: "bold",
          color:
            // theme
            (Highcharts.defaultOptions.title.style &&
              Highcharts.defaultOptions.title.style.color) ||
            "gray",
        },
      },
    },
    legend: {
      align: "right",
      x: -30,
      verticalAlign: "top",
      y: 25,
      floating: true,
      backgroundColor:
        Highcharts.defaultOptions.legend.backgroundColor || "white",
      borderColor: "#CCC",
      borderWidth: 1,
      shadow: false,
    },
    tooltip: {
      formatter: function () {
        return "<b>" + this.x + "</b><br/>" + this.series.name + ": " + this.y;
      },
    },
    plotOptions: {
      column: {
        stacking: "normal",
        dataLabels: {
          enabled: false,
        },
        maxPointWidth: 70,
      },
    },
    series: [],
  };

  const { chartdata, categories } = props;

  chartOptions.series = chartdata;
  chartOptions.xAxis.categories = categories;
  chartOptions.xAxis.max = categories.length > 8 ? 8 : categories.length - 1;
  chartOptions.xAxis.scrollbar.enabled = categories.length > 8;

  return <HighchartsReact highcharts={Highcharts} options={chartOptions} />;
}

export default GroupedBar;
