import {
    GET_SUMMARY_REQUEST,
    GET_SUMMARY_SUCCESS,
    GET_SUMMARY_FAILURE
} from './actionTypes'
import { summaryService } from '../../services/summaryService'
const fetchingSummary = ()=> ({ type: GET_SUMMARY_REQUEST })

const summaryFetched = data => ({
    type: GET_SUMMARY_SUCCESS,
    payload: data
})

const summaryFetchFailure = err => ({
    type: GET_SUMMARY_FAILURE,
    payload: err
})

export const getSummary = (filter)=> {
    return ( dispatch )=> {
        dispatch( fetchingSummary() )
        summaryService.getSummary(filter)
            .then( res => {
                dispatch( summaryFetched( res.data ) )
            })
            .catch( err => {
                dispatch( summaryFetchFailure(err.response) )
            })

    }
} 