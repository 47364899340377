import { combineReducers } from "redux";
import { overviewReducer } from "./overview/overviewReducer";
import { delayedReducer } from "./delayed/delayedReducer";
import { tripsETA } from "./tripsETA/tripsEtaReducer";
import { summaryReducer } from "./summary/summaryReducer";
import { heatmapReducer } from "./heatmap/heatmapReducer";
import { loginReducer } from "./app/loginReducer";
import { dashboardReducer } from "./dashboard/dashboardReducers";
import { loadingReducer } from "./loading/loadingReducer";
import { haltmapReducer } from "./haltmap/haltmapReducer";

export const rootReducer = combineReducers({
  app: loginReducer,
  dashboard: dashboardReducer,
  overview: overviewReducer,
  delayed: delayedReducer,
  tripseta: tripsETA,
  summary: summaryReducer,
  heatmap: heatmapReducer,
  haltmap: haltmapReducer,
  loading: loadingReducer,
});
