import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getHeatmap } from "../../redux/heatmap/heatmapActions";
import {
  Grid,
  Typography,
  Paper,
  Box,
  List,
  ListItem,
  ListItemText,
  LinearProgress,
  Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Map from "./mapBox";
import TripListModal from "../TripsModal/tripListDialog";
import { tripService } from "../../services/tripService";
import InfoIcon from '@material-ui/icons/Info';

const useStyles = makeStyles((theme) => ({
  headerBox: {
    textAlign: "center",
    marginTop: "0.7em",
    marginBottom: "0.7em",
    backgroundColor: "#F7F7F7",
    marginRight: "1em",
    marginLeft: "1em",
    padding: "0.3em",
  },
  sectionHeader: {
    ...theme.typography.sectionHeader,
    color: theme.palette.common.gunPowder,
    [theme.breakpoints.down("lg")]: {
      fontSize: "0.8rem",
    },
  },
  chartHeader: {
    ...theme.typography.chartHeader,
    [theme.breakpoints.down("lg")]: {
      fontSize: "1rem",
    },
  },
  infoIcon: {
    paddingLeft: "0.5em",
    fontSize: "20px"
  },
  lpg: {
    height: 6,
    borderRadius: 20,
    [theme.breakpoints.down("lg")]: {
      height: 5,
    },
    backgroundColor: "#FFFFFF",
  },
  bargreen: {
    borderRadius: 20,
    backgroundColor: "#28AE14",
  },
  baryellow: {
    borderRadius: 20,
    backgroundColor: "#EDBD00",
  },
}));

function Heatmap(props) {
  const classes = useStyles();

  const [open, setopen] = useState(false);
  const [trips, settrips] = useState([]);

  const clickHandler = (fieldKey, fieldName) => {
    if (props.covidUser) return;

    let { startDate, endDate } = props.filter;
    tripService
      .getTrips(fieldKey, fieldName, startDate, endDate)
      .then((res) => {
        settrips(res.data);
        setopen(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    props.getHeatmap(props.filter);
  }, [props.filter]);


  const getByInfoTitle = (title) => {
    const infoTitle = {
      'Trip Heatmap': 'Cluster representation of number of trips from top sources and destinations.'
    }
    return infoTitle[title] || title;
  }

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Paper>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{
                textAlign: "center",
                marginBottom: "3em",
                marginTop: "1em",
              }}
            >
              <Typography className={classes.chartHeader}>
                {props.heatmap.title}
                <Tooltip title={<p style={{fontSize: "14px", lineHeight: '1.2'}}>{getByInfoTitle(props.heatmap.title)}</p>}>
                    <InfoIcon className={classes.infoIcon}/>
                </Tooltip>
              </Typography>
            </Grid>
            <Grid
              item
              xs={4}
              sm={4}
              md={4}
              lg={4}
              xl={4}
              style={{ paddingLeft: "1em", paddingRight: "1em" }}
            >
              {props.heatmap.topFrequentEntities &&
                props.heatmap.topFrequentEntities.map((slice, index) => {
                  return (
                    <>
                      <Box className={classes.headerBox}>
                        <Typography className={classes.sectionHeader}>
                          {slice.title}
                        </Typography>
                      </Box>
                      <List>
                        {slice.topFrequents.map((elems, index) => {
                          return (
                            <ListItem
                              button={!props.covidUser}
                              onClick={() =>
                                clickHandler(elems.fieldKey, elems.fieldName)
                              }
                            >
                              <ListItemText
                                primary={
                                  <Grid container>
                                    <Grid
                                      item
                                      xitem
                                      xs={4}
                                      sm={4}
                                      md={4}
                                      lg={4}
                                      xl={3}
                                    >
                                      <Typography variant="body1">
                                        {elems.fieldType}
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={8}
                                      sm={8}
                                      md={8}
                                      lg={8}
                                      xl={9}
                                    >
                                      <Grid container>
                                        <Grid
                                          item
                                          xs={12}
                                          sm={12}
                                          md={12}
                                          lg={12}
                                          xl={12}
                                        >
                                          <Typography variant="body2">
                                            {elems.fieldName}
                                          </Typography>
                                        </Grid>
                                        <Grid
                                          item
                                          xs={10}
                                          sm={10}
                                          md={10}
                                          lg={10}
                                          xl={10}
                                          style={{
                                            display: "grid",
                                            alignItems: "center",
                                          }}
                                        >
                                          <LinearProgress
                                            variant="determinate"
                                            color="#000000"
                                            value={elems.valuePercent}
                                            classes={{
                                              root: classes.lpg,
                                              bar:
                                                slice.color === `#28AE14`
                                                  ? classes.bargreen
                                                  : classes.baryellow,
                                            }}
                                          />
                                        </Grid>
                                        <Grid
                                          item
                                          xs={2}
                                          sm={2}
                                          md={2}
                                          lg={2}
                                          xl={2}
                                          style={{ textAlign: "center" }}
                                        >
                                          <Typography variant="body2">
                                            {elems.value}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                }
                              />
                            </ListItem>
                          );
                        })}
                      </List>
                    </>
                  );
                })}
            </Grid>
            <Grid
              item
              xs={8}
              sm={8}
              md={8}
              lg={8}
              xl={8}
              style={{
                paddingLeft: "3em",
                paddingRight: "2em",
                paddingBottom: "1em",
              }}
            >
              <Map
                user={props.user}
                data={props.heatmap.locationsMap || []}
                covidUser={props.covidUser}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <TripListModal
        handleClose={() => setopen(false)}
        open={open}
        data={trips}
        covidUser={props.covidUser}
      />
    </Grid>
  );
}

const mapStateToProps = (state) => {
  return {
    ...state.heatmap,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getHeatmap: (filter) => dispatch(getHeatmap(filter)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Heatmap);
