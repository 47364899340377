import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { validateToken } from '../redux/app/loginActions'

const PrivateRoute = ({ component: Component, ...rest })=>{

    const { loggedIn, location, computedMatch, vaildateToken } = { ...rest}

    if(computedMatch.params.key && window.location !== window.parent.location){
        vaildateToken(computedMatch.params.key)
    }

    return(
        <Route 
            {...rest}
                render = {props => ( loggedIn ?   (<Component {...props}/>) : (<Redirect to={{ pathname: '/login'}}/>) )}/>
    )

}

const mapStateToProps = (state) => {
    return {
        ...state.app
    }
}

const mapDispatchToProps = dispatch => {
    return {
        vaildateToken: (token)=> dispatch( validateToken(token) )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute)
