import { store } from '../redux/store'
var axios = require('axios')
var ES6Promise = require('es6-promise')
ES6Promise.polyfill()

const rootService = (props) => {
  return (
    {
      sctAnalytics: axios.create({
        baseURL: `https://wdhn2eyaz0.execute-api.ap-south-1.amazonaws.com/dev/api/`,
        headers: {
          Authorization: `Bearer ${store.getState().app.token}`
        },
      })
    }
  )
} 

export default rootService