import { 
    GET_HEATMAP_REQUEST,
    GET_HEATMAP_SUCCESS,
    GET_HEATMAP_FAILURE
} from './actionTypes'

let initState = {
    heatmap: {}
}

export const heatmapReducer = ( state = initState, action )=> {

    switch(action.type){
        case GET_HEATMAP_REQUEST :
            return {
                ...state
            }
        case GET_HEATMAP_SUCCESS:
            return {
                ...state,
                heatmap: {
                    ...action.payload
                }
            }
        case GET_HEATMAP_FAILURE : 
            return {
                ...state,
                heatmap: {}
            }
        default :
            return {
                ...state
            }
    }
}