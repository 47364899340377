import rootService from "./rootService";

export const heatmapService = {
  getHeatmap: (filter) => {
    let { startDate, endDate, childuser } = filter;
    return rootService().sctAnalytics.post(
      `/v3/heatmap?starttime=${startDate}&endtime=${endDate}${
        childuser ? `&childUser=${childuser}` : ``
      }&limit=all`,
      {
        ...filter,
      }
    );
  },
};
