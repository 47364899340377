import {  
    GET_FILTER_DATA_REQUEST,
    GET_FILTER_DATA_SUCCESS,
    GET_FILTER_DATA_FAILURE
} from './actionTypes'

const initState = {
    filterdata: {}
}

export const dashboardReducer = ( state = initState, action)=> {

    switch(action.type){
        case GET_FILTER_DATA_REQUEST:
            return {
                ...state
            }
        case GET_FILTER_DATA_SUCCESS: 
            return {
                ...state,
                filterdata: {...action.payload}
            }
        case GET_FILTER_DATA_FAILURE: 
            return {
                ...state,
                filterdata: {}
            }
        default:
            return {
                ...state
            }
    }
}