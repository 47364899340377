import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getTripsETA } from "../../redux/tripsETA/tripsEtaActions";
import { Grid, Paper, Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";

const useStyles = makeStyles((theme) => ({
  chartHeader: {
    ...theme.typography.chartHeader,
    paddingTop: "2em",
    paddingLeft: "2em",
    [theme.breakpoints.down("lg")]: {
      fontSize: "1rem",
    },
  },
  typeButtonActive: {
    ...theme.typography.typeButtonActive,
    paddingLeft: "1.5em",
    paddingRight: "1.5em",
    [theme.breakpoints.down("lg")]: {
      fontSize: "0.68rem",
    },
  },
  typeButtonInactive: {
    ...theme.typography.typeButtonInactive,
    paddingLeft: "1.5em",
    paddingRight: "1.5em",
    [theme.breakpoints.down("lg")]: {
      fontSize: "0.68rem",
    },
  },
  filterButtonActive: {
    ...theme.typography.filterButtonActive,
    paddingLeft: "1.5em",
    paddingRight: "1.5em",
    margin: "1em",
    [theme.breakpoints.down("lg")]: {
      fontSize: "0.68rem",
    },
  },
  filterButtonInactive: {
    ...theme.typography.filterButtonInactive,
    paddingLeft: "1.5em",
    paddingRight: "1.5em",
    margin: "1em",
    [theme.breakpoints.down("lg")]: {
      fontSize: "0.68rem",
    },
  },
}));

function TripsETA(props) {
  const classes = useStyles();
  const [filter, setfilter] = useState("");
  const [type, settype] = useState("absolute");

  let chartOptions = {
    chart: {
      type: "column",
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: [],
      min: 0,
      max: 8,
      scrollbar: {
        enabled: true,
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "",
      },
      stackLabels: {
        enabled: false,
        style: {
          fontWeight: "bold",
          color:
            // theme
            (Highcharts.defaultOptions.title.style &&
              Highcharts.defaultOptions.title.style.color) ||
            "gray",
        },
      },
    },
    legend: {
      align: "right",
      x: -30,
      verticalAlign: "top",
      y: 25,
      floating: true,
      backgroundColor:
        Highcharts.defaultOptions.legend.backgroundColor || "white",
      borderColor: "#CCC",
      borderWidth: 1,
      shadow: false,
      enabled: false,
    },
    tooltip: {
      headerFormat: "<b>{point.x}</b><br/>",
      pointFormat: "{series.name}: {point.y}",
    },
    plotOptions: {
      column: {
        stacking: "normal",
        dataLabels: {
          enabled: false,
        },
        maxPointWidth: 100,
      },
    },
    series: [],
  };

  let barChartData = {
    labels: [],
    datasets: [
      {
        name: "Trips",
        color: "rgb(99, 50, 206, 0.4)",
        data: [],
      },
    ],
  };

  if (Object.keys(props.data).length > 0 && filter !== "") {
    barChartData.labels = [];
    barChartData.datasets[0].data = [];

    props.data[filter] &&
      props.data[filter].map((slice) => {
        barChartData.labels.push(slice.key);
        if (type === "absolute") {
          barChartData.datasets[0].data.push(slice.value[0]);
        } else {
          barChartData.datasets[0].data.push(100);
        }
      });

    chartOptions.xAxis.categories = barChartData.labels;
    chartOptions.series = barChartData.datasets;
    chartOptions.xAxis.max =
      barChartData.labels.length > 8 ? 8 : barChartData.labels.length - 1;
    chartOptions.xAxis.scrollbar.enabled = barChartData.labels.length > 8;
  }

  useEffect(() => {
    props.getTrips(props.filter);
  }, [props.filter]);

  useEffect(() => {
    setfilter(props.filterProperties[0] ? props.filterProperties[0].key : "");
  }, [props.filterProperties]);

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Paper elevation={4}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography className={classes.chartHeader}>
                {props.title}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{
                textAlign: "center",
                marginBottom: "3em",
                marginTop: "-2em",
              }}
            ></Grid>
            <Grid
              item
              xs={9}
              sm={9}
              md={9}
              lg={9}
              xl={9}
              style={{ paddingLeft: "2em", marginBottom: "2em" }}
            >
              {props.filterProperties.map((slice) => {
                return (
                  <Button
                    variant="outlined"
                    className={
                      slice.key === filter
                        ? classes.filterButtonActive
                        : classes.filterButtonInactive
                    }
                    onClick={() => {
                      setfilter(slice.key);
                    }}
                  >
                    {slice.label}
                  </Button>
                );
              })}
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div
                style={{ padding: "1em 2em 1em 2em" }}
                className="scroll-horizental-histo"
              >
                <HighchartsReact
                  highcharts={Highcharts}
                  options={chartOptions}
                />
              </div>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => {
  return {
    ...state.tripseta,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTrips: (filter) => dispatch(getTripsETA(filter)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TripsETA);
