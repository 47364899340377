import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getTripsETA } from "../../redux/tripsETA/tripsEtaActions";
import { Grid, Paper, Button, Typography, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import ReactGA from 'react-ga';
import InfoIcon from '@material-ui/icons/Info';
import GetAppIcon from '@material-ui/icons/GetApp';
import XLSX from 'xlsx';
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import HC_exporting from 'highcharts/modules/exporting';

HC_exporting(Highcharts)

const useStyles = makeStyles((theme) => ({
  chartHeader: {
    ...theme.typography.chartHeader,
    paddingTop: "2em",
    paddingBottom: "1em",
    paddingLeft: "2em",
    [theme.breakpoints.down("lg")]: {
      fontSize: "1rem",
    },
  },
  infoIcon: {
    paddingLeft: "1em",
    fontSize: "20px"
  },
  downloadIcon: {
    paddingLeft: "0.5em",
    paddingTop: "0.1em",
    fontSize: "25px",
    cursor: "pointer"
  },
  filterButtonActive: {
    ...theme.typography.filterButtonActive,
    paddingLeft: "1.5em",
    paddingRight: "1.5em",
    margin: "0.5em",
    [theme.breakpoints.down("lg")]: {
      fontSize: "0.68rem",
    },
  },
  filterButtonInactive: {
    ...theme.typography.filterButtonInactive,
    paddingLeft: "1.5em",
    paddingRight: "1.5em",
    margin: "0.5em",
    [theme.breakpoints.down("lg")]: {
      fontSize: "0.68rem",
    },
  },
}));

function TripsETA(props) {
  const classes = useStyles();
  const [filter, setfilter] = useState("");
  const chartRef = React.useRef(null);
  const [chartdata, setChartdata] = useState({
    categories: [],
    series: [],
  });

  useEffect(() => {
    props.getTrips(props.filter);
  }, [props.filter]);

  useEffect(() => {
    setfilter(props.filterProperties[0] ? props.filterProperties[0].key : "");
  }, [props.filterProperties]);

  useEffect(() => {
    let formattedchartdata = [
      {
        name: "SLA",
        data: [],
        stack: "male",
        color: "rgba(99, 50, 206, 0.5)",
      },
      {
        name: "TAT",
        data: [],
        stack: "female",
        color: "rgba(99, 50, 206, 0.8)",
      },
    ];
    let categories = [];
    if (filter) {
      formattedchartdata =
        props.data[filter].absolute &&
        props.data[filter].absolute.reduce((prevres, item) => {
          prevres[0].data.push(item["SLA"]);
          prevres[1].data.push(item["TAT"]);
          categories.push(item.key);
          return prevres;
        }, formattedchartdata);
    }
    setChartdata({
      ...chartdata,
      categories: categories,
      series: formattedchartdata,
    });
  }, [filter, props.filterProperties]);

  const logEvent = (action) => {
    ReactGA.event({
      category: props.user,
      action
    });
  }

  const getByInfoTitle = (title) => {
    const infoTitle = {
      'Trips ETA': 'Comparison between transit time and TAT based on Lane and Transporter'
    }
    return infoTitle[title] || title;
  }

  const downloadData = () => {
    try {
      console.log(props)
      const lanes = [];
      const transporters = [];
      if (props.data?.lane?.absolute)
        props.data.lane.absolute.forEach(lane => {
          lanes.push({
            Lane: lane.key,
            SLA: lane.SLA,
            TAT: lane.TAT
          })
        })
      if (props.data?.transporter?.absolute)
        props.data.transporter.absolute.forEach(transporter => {
          transporters.push({
            Transporter: transporter.key,
            SLA: transporter.SLA,
            TAT: transporter.TAT
          })
        })
      const wb = XLSX.utils.book_new();
      const file_name = `Trips ETA.xlsx`;
      XLSX.utils.book_append_sheet(
          wb, XLSX.utils.json_to_sheet(lanes),
          "Lanes"
      );
      XLSX.utils.book_append_sheet(
          wb, XLSX.utils.json_to_sheet(transporters),
          "Transporters"
      );
      XLSX.writeFile(wb, file_name);
    } catch (err) {
      console.log(err)
    }
  }

  let chartOptions = {
    exporting: {
      enabled: true,
      chartOptions: {
        xAxis: [{
          categories: [],
          max: 30,
          labels: {
            style: {
              fontSize: "7px"
            }
          }
        }]
      }
    },
    chart: {
      type: "column",
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: [],
      min: 0,
      max: 8,
      scrollbar: {
        enabled: true,
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "",
      },
      stackLabels: {
        enabled: false,
        style: {
          fontWeight: "bold",
          color:
            // theme
            (Highcharts.defaultOptions.title.style &&
              Highcharts.defaultOptions.title.style.color) ||
            "gray",
        },
      },
    },
    legend: {
      align: "right",
      x: -30,
      verticalAlign: "top",
      y: 25,
      floating: true,
      backgroundColor:
        Highcharts.defaultOptions.legend.backgroundColor || "white",
      borderColor: "#CCC",
      borderWidth: 1,
      shadow: false,
    },
    tooltip: {
      formatter: function () {
        return "<b>" + this.x + "</b><br/>" + this.series.name + ": " + this.y;
      },
    },
    plotOptions: {
      column: {
        stacking: "normal",
        dataLabels: {
          enabled: false,
        },
        maxPointWidth: 70,
      },
    },
    series: [],
  };

  chartOptions.series = chartdata.series;
  chartOptions.xAxis.categories = chartdata.categories;
  chartOptions.exporting.chartOptions.xAxis[0].categories = chartdata.categories;
  chartOptions.exporting.chartOptions.xAxis[0].max = (chartdata.categories || []).length > 30 ? 30 : (chartdata.categories || []).length;
  chartOptions.xAxis.max = chartdata.categories.length > 8 ? 8 : chartdata.categories.length - 1;
  chartOptions.xAxis.scrollbar.enabled = chartdata.categories.length > 8;

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Paper elevation={4}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography className={classes.chartHeader}>
                {props.title}
                <Tooltip title={<p style={{fontSize: "14px", lineHeight: '1.2'}}>{getByInfoTitle('Trips ETA')}</p>}>
                    <InfoIcon className={classes.infoIcon}/>
                </Tooltip>
                <Tooltip title={<p style={{fontSize: "14px", lineHeight: '1.2'}}>Download Data</p>}>
                  <GetAppIcon className={classes.downloadIcon} onClick={downloadData}/>
                </Tooltip>
              </Typography>
            </Grid>

            <Grid
              item
              xs={9}
              sm={9}
              md={9}
              lg={9}
              xl={9}
              style={{ paddingLeft: "2em", marginBottom: "2em" }}
            >
              {props.filterProperties.map((slice) => {
                return (
                  <Button
                    variant="outlined"
                    className={
                      slice.key === filter
                        ? classes.filterButtonActive
                        : classes.filterButtonInactive
                    }
                    onClick={() => {
                      logEvent(`Clicked on ${slice.label} in Trips ETA`);
                      setfilter(slice.key);
                    }}
                  >
                    {slice.label}
                  </Button>
                );
              })}
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div
                style={{ padding: "1em 2em 1em 2em" }}
                className="scroll-horizental-histo"
              >
                <HighchartsReact 
                  highcharts={Highcharts} 
                  options={chartOptions} 
                  ref={ chartRef }
                />
              </div>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => {
  return {
    ...state.tripseta,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTrips: (filter) => dispatch(getTripsETA(filter)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TripsETA);
