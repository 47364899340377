export let customStyles = {
  control: (base) => ({
    ...base,
    minHeight: "2.9em",
    width: "50em",
    marginTop: "0.5em",
    marginLeft: "0.5em",
    marginRight: "0.5em",
    border: "1px solid rgba(73, 73, 240, 0.5)",
    fontSize: "1rem",
    fontFamily: "Montserrat",
    backgroundColor: "transparent",
  }),
  dropdownIndicator: (base) => ({
    ...base,
    padding: 4,
  }),
  clearIndicator: (base) => ({
    ...base,
    padding: 4,
  }),
  multiValue: (base) => ({
    ...base,
    // backgroundColor: variables.colorPrimaryLighter
  }),
  valueContainer: (base) => ({
    ...base,
    padding: "0px 6px",
  }),
  input: (base) => ({
    ...base,
    margin: 0,
    padding: 0,
  }),
};

export const selectStyleGenerator = (props) => {
  const { width, marginLeft } = props;
  return {
    control: (base) => ({
      ...base,
      minHeight: "2.9em",
      width: width || "100%",
      marginTop: "0.8em",
      marginLeft: marginLeft || "0em",
      marginRight: "0.5em",
      border: "1px solid rgba(73, 73, 240, 0.5)",
      fontSize: "1rem",
      fontFamily: "Montserrat",
      backgroundColor: "transparent",
    }),
    dropdownIndicator: (base) => ({
      ...base,
      padding: 4,
    }),
    clearIndicator: (base) => ({
      ...base,
      padding: 4,
    }),
    multiValue: (base) => ({
      ...base,
      // backgroundColor: variables.colorPrimaryLighter
    }),
    valueContainer: (base) => ({
      ...base,
      padding: "0px 6px",
    }),
    input: (base) => ({
      ...base,
      margin: 0,
      padding: 0,
    }),
    menuList: (base) => ({
      fontSize: "1rem",
      fonWeight: 500,
      fontFamily: "Montserrat",
      maxHeight: "30em",
      overflowY: "auto",
    }),
  };
};
