import axios from 'axios'

export const authService = {

    login( credentials ) {
        return axios.post('https://wdhn2eyaz0.execute-api.ap-south-1.amazonaws.com/dev/login',{}, {
            auth: credentials
        })
    },
    validateToken( token ){
        return axios.get(
            'https://wdhn2eyaz0.execute-api.ap-south-1.amazonaws.com/dev/api/v2/validatetoken',
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        )
    }
}