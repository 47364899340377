import {
  SIGNING_IN,
  SIGN_IN_SUCCESS,
  SIGN_IN_FAILURE,
  LOGOUT,
  TOKEN_VALIDATE_REQUEST,
  TOKEN_VALIDATE_SUCCESS,
  TOKEN_VALIDATE_FAILURE,
} from "./actionTypes";

const initState = {
  validated: true,
};

export const loginReducer = (state = initState, action) => {
  switch (action.type) {
    case SIGNING_IN:
      return {
        ...state,
        loading: true,
      };
    case SIGN_IN_SUCCESS:
      return {
        ...state,
        ...action.payload.result,
        loading: false,
        loggedIn: true,
      };
    case SIGN_IN_FAILURE:
      return {
        ...state,
        loading: false,
        loggedIn: false,
      };
    case LOGOUT:
      return {
        ...initState,
      };
    case TOKEN_VALIDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TOKEN_VALIDATE_SUCCESS:
      return {
        ...state,
        childUser: action.payload.childUser
          ? [...action.payload.childUser]
          : [],
        filters: { ...action.payload.filters },
        filtersDefaultValue: {
          fromTime: action.payload.fromTime,
          toTime: action.payload.toTime,
        },
        views: { ...action.payload.views },
        ...action.payload.user_data,
        loading: false,
        loggedIn: true,
        validated: true,
      };
    case TOKEN_VALIDATE_FAILURE:
      return {
        ...state,
        loading: false,
        loggedIn: false,
        token: "",
        validated: false,
      };
    default: {
      return state;
    }
  }
};
